import React, {useEffect, useState} from "react";
import {DiskData, ServerData} from "../../../lib/transform-json";
import {useLanguage} from "../../../contexts/LanguageContext";
import {Input} from "../../shared/Input";
import {X} from "lucide-react";

type RaspberryPiServerType = {
    serverData: ServerData;
    setForm: (form: ServerData) => void;
    loading: boolean;
    error: Record<string, { value: string | undefined }>;
}

export const RaspberryPiServer: React.FC<RaspberryPiServerType> = ({serverData, setForm, loading, error}) => {

    const {translate} = useLanguage();

    const addDisk = () => {
        setForm({
            ...serverData,
            disks: [
                ...serverData.disks,
                {
                    id: serverData.disks.length + 10000,
                    name: '',
                    count_machine_min: 0,
                    count_machine_max: 0,
                    start_machine: 0,
                    count_machine: 0
                }
            ]
        })
    }

    return (
        <div className={"flex flex-col gap-1"}>
            <div className={"grid grid-cols-2 flex-row gap-2"}>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        <Input
                            id={"name"}
                            label={translate('servers.modal.name-server')}
                            type={"text"}
                            value={serverData.name}
                            onChange={(e) => setForm({...serverData, name: e.target.value})}
                            error={error.name?.value}
                            disable={loading}
                        />
                        <Input
                            id={"identifier"}
                            label={"Identifier Server"}
                            type={"text"}
                            value={serverData.identifier}
                            onChange={(e) => setForm({...serverData, identifier: e.target.value})}
                            error={error.identifier?.value}
                            disable={loading || (serverData.id !== null)}
                        />
                        <Input
                            id={"task_in_queue"}
                            label={"Tasks In Queue"}
                            value={serverData.server_params.task_in_queue || 0}
                            onChange={(e) =>
                                setForm({
                                    ...serverData,
                                    server_params: {
                                        ...serverData.server_params,
                                        task_in_queue: Number(e.target.value)
                                    }
                                })}
                            disable={loading}
                            type={"number"}/>
                    </div>
                </div>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        <span className={"text-xs text-gray-dark dark:text-white font-bold"}>{translate('servers.modal.disks')}</span>
                        <div className={"flex flex-col gap-3"}>
                            <div className="grid grid-cols-2 gap-2">
                                {serverData.disks.map((disk, index) => (
                                    <div key={index} className={"bg-green/10 rounded-md p-2"}>
                                        <div className="flex flex-col gap-3">
                                            <div className={"flex flex-row justify-end"}>
                                                <div
                                                    onClick={() => setForm({
                                                        ...serverData,
                                                        disks: serverData.disks.filter(d => d.id !== disk.id)
                                                    })}
                                                    className={"p-0.5 rounded-md bg-red/50 hover:bg-red/80 cursor-pointer transition-all"}>
                                                    <X className={"text-gray-dark dark:text-white"} size={14}/>
                                                </div>
                                            </div>
                                            <Input
                                                label={"Name"}
                                                value={disk.name}
                                                onChange={(e) =>
                                                    // setDisks(disks.map(d =>
                                                    //     d.id === disk.id
                                                    //         ? {...d, name: e.target.value}  // обновляем диск, если id совпадает
                                                    //         : d  // возвращаем остальные диски без изменений
                                                    // ))
                                                    setForm({
                                                        ...serverData,
                                                        disks: serverData.disks.map(d =>
                                                            d.id === disk.id
                                                                ? {...d, name: e.target.value}
                                                                : d
                                                        )
                                                    })
                                                }
                                                disable={loading || (serverData.id !== null && disk.id!! < 10000)}
                                                type={"text"}/>
                                            <span
                                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{translate('servers.modal.range-machines')}</span>
                                            <div className={"flex flex-row gap-2"}>
                                                <Input
                                                    id={"min_count_machine"}
                                                    label={"Min"}
                                                    value={disk.count_machine_min || 0}
                                                    onChange={(e) =>
                                                        // setDisks(disks.map(d =>
                                                        //     d.id === disk.id
                                                        //         ? {...d, count_machine_min: Number(e.target.value)}  // обновляем диск, если id совпадает
                                                        //         : d  // возвращаем остальные диски без изменений
                                                        // ))
                                                        setForm({
                                                            ...serverData,
                                                            disks: serverData.disks.map(d =>
                                                                d.id === disk.id
                                                                    ? {...d, count_machine_min: Number(e.target.value)}
                                                                    : d
                                                            )
                                                        })
                                                    }
                                                    disable={loading}
                                                    type={"number"}/>

                                                <Input
                                                    id={"max_count_machine"}
                                                    label={"Max"}
                                                    value={disk.count_machine_max || 0}
                                                    onChange={(e) =>
                                                        // setDisks(disks.map(d =>
                                                        //     d.id === disk.id
                                                        //         ? {...d, count_machine_max: Number(e.target.value)}  // обновляем диск, если id совпадает
                                                        //         : d  // возвращаем остальные диски без изменений
                                                        // ))
                                                        setForm({
                                                            ...serverData,
                                                            disks: serverData.disks.map(d =>
                                                                d.id === disk.id
                                                                    ? {...d, count_machine_max: Number(e.target.value)}
                                                                    : d
                                                            )
                                                        })
                                                    }
                                                    disable={loading}
                                                    type={"number"}/>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                                <div
                                    onClick={() => !loading && addDisk()}
                                    className={`h-fit ${loading ? 'bg-gray/50' : 'bg-green/50 hover:bg-green/80'}  rounded-md p-2 cursor-pointer text-xs text-gray-dark dark:text-white transition-all`}>
                                    {translate('def.add')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
