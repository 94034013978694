import React from "react";
import {LocaleData} from "../../lib/transform-json";
import {useLanguage} from "../../contexts/LanguageContext";

type LocaleItemProps = {
    data: LocaleData;
}

const LocaleItem: React.FC<LocaleItemProps> = ({data}) => {

    const {translate} = useLanguage();

    return (
        <div
            className={"flex flex-row w-full gap-2 p-2 rounded-md border border-gray-border dark:border-gray-dark-border overflow-hidden"}>
            <div className={"flex justify-center items-center p-3"}>
                <img
                    className={"h-8 w-12 rounded-md overflow-hidden"}
                    alt={data.country.toUpperCase()}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.country.toUpperCase()}.svg`}/>
            </div>
            <div className={"flex flex-row justify-between w-full mr-5"}>
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex flex-row gap-2"}>
                        <span className={"text-xs text-gray font-bold"}>{translate('locale.name')}:</span>
                        <span
                            className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.name}</span>
                    </div>
                    <div className={"flex flex-row gap-2"}>
                        <span className={"text-xs text-gray font-bold"}>{translate('locale.country')}:</span>
                        <span
                            className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.country.toUpperCase()}</span>
                    </div>
                    <div className={"flex flex-row gap-2"}>
                        <span className={"text-xs text-gray font-bold"}>{translate('locale.language')}:</span>
                        <span
                            className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.language.toUpperCase()}</span>
                    </div>
                </div>
                <div className={"flex flex-row gap-2"}>
                    <span className={"text-xs text-gray font-bold"}>{translate('locale.type')}:</span>
                    {data.type && (
                        <span
                            className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.type.toUpperCase()}</span>
                    )}
                </div>
            </div>
        </div>
    )
}

const PlaceholderLocaleItem: React.FC = () => {
    return (
        <div role="status"
             className={"flex flex-row w-full rounded-md border border-gray-border dark:border-gray-dark-border p-2"}>
            <div className={"flex justify-center items-center p-3"}>
                <div className={"h-10 w-10 bg-gray/50 rounded-md animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-col gap-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                <div className={"h-3 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                <div className={"h-3 w-36 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
        </div>
    )
}

export {LocaleItem, PlaceholderLocaleItem}
