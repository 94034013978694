import React, {useEffect, useRef, useState} from 'react';
import {ChartNoAxesCombined} from "lucide-react";
import {useClickAway} from "react-use";

export enum DistributionOption {
    Equal = 'Equal',
    Increase = 'Increase',
    Parabolic = 'Parabolic',
}

interface InstallDistributionMenuProps {
    onSelect: (option: DistributionOption) => void;
}

const InstallDistributionMenu: React.FC<InstallDistributionMenuProps> = ({onSelect}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHiding, setIsHiding] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    const isHidingRef = useRef(isHiding);

    useEffect(() => {
        isHidingRef.current = isHiding;
    }, [isHiding]);

    useClickAway(menuRef, () => {
        if (isOpen) {
            setIsHiding(true);

            setTimeout(() => {
                if (isHidingRef.current) {
                    setIsHiding(false);
                    setIsOpen(false);
                }
            }, 300);
        } else {
            setIsOpen(true);
        }
    });

    const handleSelect = (option: DistributionOption) => {
        onSelect(option);
        closeMenu();
    };

    return (
        <div className="relative">
            <div
                onClick={toggleMenu}
                className="p-1 bg-gray/20 hover:bg-gray/40 transition-all text-gray-dark dark:text-gray dark:hover:text-blue hover:text-blue rounded-md cursor-pointer"
            >
                <ChartNoAxesCombined size={20}/>
            </div>

            {isOpen && (
                <div
                    ref={menuRef}
                    className={`absolute mt-2 right-0 w-40 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-50 overflow-hidden ${isHiding ? 'animate-fade-out' : 'animate-fade-in'}`}
                >
                    <div className="flex flex-col py-2 px-1 text-xs text-left">
                        <div
                            className={"flex flex-row dark:hover:text-blue hover:text-blue hover:bg-blue/10 dark:hover:bg-blue/30 rounded-md items-center pl-2 gap-1"}
                            onClick={() => handleSelect(DistributionOption.Equal)}>
                            <span>
                                <svg viewBox="0 0 12 12" height="12" width="12" xmlns="http://www.w3.org/2000/svg" className="w-100 h-100">
                                    <path xmlns="http://www.w3.org/2000/svg" opacity="0.4"
                                          d="M8.33333 5.63538H7C6.91159 5.63538 6.82681 5.67049 6.7643 5.73301C6.70179 5.79552 6.66667 5.8803 6.66667 5.96871V11.3333C6.66667 11.4217 6.70179 11.5065 6.7643 11.569C6.82681 11.6315 6.91159 11.6666 7 11.6666H8.33333C8.42174 11.6666 8.50652 11.6315 8.56904 11.569C8.63155 11.5065 8.66667 11.4217 8.66667 11.3333V5.96871C8.66667 5.8803 8.63155 5.79552 8.56904 5.73301C8.50652 5.67049 8.42174 5.63538 8.33333 5.63538ZM11.6667 5.63538H10.3333C10.2449 5.63538 10.1601 5.67049 10.0976 5.73301C10.0351 5.79552 10 5.8803 10 5.96871V11.3333C10 11.4217 10.0351 11.5065 10.0976 11.569C10.1601 11.6315 10.2449 11.6666 10.3333 11.6666H11.6667C11.7551 11.6666 11.8399 11.6315 11.9024 11.569C11.9649 11.5065 12 11.4217 12 11.3333V5.96871C12 5.8803 11.9649 5.79552 11.9024 5.73301C11.8399 5.67049 11.7551 5.63538 11.6667 5.63538ZM5 5.63538H3.66667C3.57826 5.63538 3.49348 5.67049 3.43096 5.73301C3.36845 5.79552 3.33333 5.8803 3.33333 5.96871V11.3333C3.33333 11.4217 3.36845 11.5065 3.43096 11.569C3.49348 11.6315 3.57826 11.6666 3.66667 11.6666H5C5.08841 11.6666 5.17319 11.6315 5.2357 11.569C5.29821 11.5065 5.33333 11.4217 5.33333 11.3333V5.96871C5.33333 5.8803 5.29821 5.79552 5.2357 5.73301C5.17319 5.67049 5.08841 5.63538 5 5.63538ZM1.66667 5.63538H0.333333C0.244928 5.63538 0.160143 5.67049 0.0976311 5.73301C0.035119 5.79552 0 5.8803 0 5.96871L0 11.3333C0 11.4217 0.035119 11.5065 0.0976311 11.569C0.160143 11.6315 0.244928 11.6666 0.333333 11.6666H1.66667C1.75507 11.6666 1.83986 11.6315 1.90237 11.569C1.96488 11.5065 2 11.4217 2 11.3333V5.96871C2 5.8803 1.96488 5.79552 1.90237 5.73301C1.83986 5.67049 1.75507 5.63538 1.66667 5.63538Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1 3.83337C1.37014 3.83337 1.69331 3.63227 1.86622 3.33337H3.46728C3.64018 3.63227 3.96335 3.83337 4.3335 3.83337C4.70364 3.83337 5.02681 3.63227 5.19971 3.33337H6.80029C6.97319 3.63227 7.29636 3.83337 7.6665 3.83337C8.03664 3.83337 8.35982 3.63227 8.53272 3.33337H10.1338C10.3067 3.63227 10.6299 3.83337 11 3.83337C11.5523 3.83337 12 3.38566 12 2.83337C12 2.28109 11.5523 1.83337 11 1.83337C10.6299 1.83337 10.3067 2.03447 10.1338 2.33337H8.53272C8.35982 2.03447 8.03664 1.83337 7.6665 1.83337C7.29636 1.83337 6.97319 2.03447 6.80029 2.33337H5.19971C5.02681 2.03447 4.70364 1.83337 4.3335 1.83337C3.96335 1.83337 3.64018 2.03447 3.46728 2.33337H1.86622C1.69331 2.03447 1.37014 1.83337 1 1.83337C0.447715 1.83337 0 2.28109 0 2.83337C0 3.38566 0.447715 3.83337 1 3.83337Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1 3.83337C1.37014 3.83337 1.69331 3.63227 1.86622 3.33337H3.46728C3.64018 3.63227 3.96335 3.83337 4.3335 3.83337C4.70364 3.83337 5.02681 3.63227 5.19971 3.33337H6.80029C6.97319 3.63227 7.29636 3.83337 7.6665 3.83337C8.03664 3.83337 8.35982 3.63227 8.53272 3.33337H10.1338C10.3067 3.63227 10.6299 3.83337 11 3.83337C11.5523 3.83337 12 3.38566 12 2.83337C12 2.28109 11.5523 1.83337 11 1.83337C10.6299 1.83337 10.3067 2.03447 10.1338 2.33337H8.53272C8.35982 2.03447 8.03664 1.83337 7.6665 1.83337C7.29636 1.83337 6.97319 2.03447 6.80029 2.33337H5.19971C5.02681 2.03447 4.70364 1.83337 4.3335 1.83337C3.96335 1.83337 3.64018 2.03447 3.46728 2.33337H1.86622C1.69331 2.03447 1.37014 1.83337 1 1.83337C0.447715 1.83337 0 2.28109 0 2.83337C0 3.38566 0.447715 3.83337 1 3.83337Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1 3.83337C1.37014 3.83337 1.69331 3.63227 1.86622 3.33337H3.46728C3.64018 3.63227 3.96335 3.83337 4.3335 3.83337C4.70364 3.83337 5.02681 3.63227 5.19971 3.33337H6.80029C6.97319 3.63227 7.29636 3.83337 7.6665 3.83337C8.03664 3.83337 8.35982 3.63227 8.53272 3.33337H10.1338C10.3067 3.63227 10.6299 3.83337 11 3.83337C11.5523 3.83337 12 3.38566 12 2.83337C12 2.28109 11.5523 1.83337 11 1.83337C10.6299 1.83337 10.3067 2.03447 10.1338 2.33337H8.53272C8.35982 2.03447 8.03664 1.83337 7.6665 1.83337C7.29636 1.83337 6.97319 2.03447 6.80029 2.33337H5.19971C5.02681 2.03447 4.70364 1.83337 4.3335 1.83337C3.96335 1.83337 3.64018 2.03447 3.46728 2.33337H1.86622C1.69331 2.03447 1.37014 1.83337 1 1.83337C0.447715 1.83337 0 2.28109 0 2.83337C0 3.38566 0.447715 3.83337 1 3.83337Z"></path>
                                </svg>
                            </span>
                            <span
                                className="px-2 py-1">
                                Equal
                            </span>
                        </div>
                        <div
                            className={"flex flex-row gap-1 dark:hover:text-blue hover:text-blue hover:bg-blue/10 dark:hover:bg-blue/30 rounded-md items-center pl-2"}
                            onClick={() => handleSelect(DistributionOption.Increase)}>
                            <span>
                                <svg viewBox="0 0 12 12" height="12" width="12" xmlns="http://www.w3.org/2000/svg" className="w-100 h-100">
                                    <path xmlns="http://www.w3.org/2000/svg" opacity="0.4"
                                          d="M8.33333 6.45833H7C6.91159 6.45833 6.82681 6.49345 6.7643 6.55596C6.70179 6.61848 6.66667 6.70326 6.66667 6.79167V11.3333C6.66667 11.4217 6.70179 11.5065 6.7643 11.569C6.82681 11.6315 6.91159 11.6667 7 11.6667H8.33333C8.42174 11.6667 8.50652 11.6315 8.56904 11.569C8.63155 11.5065 8.66667 11.4217 8.66667 11.3333V6.79167C8.66667 6.70326 8.63155 6.61848 8.56904 6.55596C8.50652 6.49345 8.42174 6.45833 8.33333 6.45833ZM11.6667 4.625H10.3333C10.2449 4.625 10.1601 4.66012 10.0976 4.72263C10.0351 4.78514 10 4.86993 10 4.95833V11.3333C10 11.4217 10.0351 11.5065 10.0976 11.569C10.1601 11.6315 10.2449 11.6667 10.3333 11.6667H11.6667C11.7551 11.6667 11.8399 11.6315 11.9024 11.569C11.9649 11.5065 12 11.4217 12 11.3333V4.95833C12 4.86993 11.9649 4.78514 11.9024 4.72263C11.8399 4.66012 11.7551 4.625 11.6667 4.625ZM5 8.09375H3.66667C3.57826 8.09375 3.49348 8.12887 3.43096 8.19138C3.36845 8.25389 3.33333 8.33868 3.33333 8.42708V11.3333C3.33333 11.4217 3.36845 11.5065 3.43096 11.569C3.49348 11.6315 3.57826 11.6667 3.66667 11.6667H5C5.08841 11.6667 5.17319 11.6315 5.2357 11.569C5.29821 11.5065 5.33333 11.4217 5.33333 11.3333V8.42708C5.33333 8.33868 5.29821 8.25389 5.2357 8.19138C5.17319 8.12887 5.08841 8.09375 5 8.09375ZM1.66667 9.13542H0.333333C0.244928 9.13542 0.160143 9.17054 0.0976311 9.23305C0.035119 9.29556 0 9.38034 0 9.46875L0 11.3333C0 11.4217 0.035119 11.5065 0.0976311 11.569C0.160143 11.6315 0.244928 11.6667 0.333333 11.6667H1.66667C1.75507 11.6667 1.83986 11.6315 1.90237 11.569C1.96488 11.5065 2 11.4217 2 11.3333V9.46875C2 9.38034 1.96488 9.29556 1.90237 9.23305C1.83986 9.17054 1.75507 9.13542 1.66667 9.13542Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12 2C12 2.55228 11.5523 3 11 3C10.9231 3 10.8481 2.99131 10.7762 2.97485C10.1351 3.55549 9.42182 4.09176 8.66579 4.57984C8.6457 5.11442 8.20599 5.54163 7.6665 5.54163C7.50337 5.54163 7.34937 5.50257 7.21333 5.43329C6.59423 5.76439 5.96045 6.06504 5.32575 6.33342C5.26422 6.82669 4.84343 7.20837 4.3335 7.20837C4.08088 7.20837 3.85014 7.1147 3.67412 6.9602C3.09221 7.15624 2.5199 7.32304 1.96829 7.45916C1.85701 7.89007 1.46568 8.20837 1 8.20837C0.447715 8.20837 0 7.76066 0 7.20837C0 6.65609 0.447715 6.20837 1 6.20837C1.27306 6.20837 1.52056 6.31782 1.70102 6.49524C2.23509 6.36441 2.78931 6.20327 3.35246 6.0136C3.44308 5.55455 3.84786 5.20837 4.3335 5.20837C4.56067 5.20837 4.77014 5.28412 4.93805 5.41174C5.52014 5.16547 6.10067 4.8912 6.66769 4.59084C6.6669 4.57453 6.6665 4.55813 6.6665 4.54163C6.6665 3.98934 7.11422 3.54163 7.6665 3.54163C7.86077 3.54163 8.0421 3.59702 8.19556 3.69288C8.85683 3.26153 9.48017 2.79259 10.0426 2.28973C10.0149 2.19802 10 2.10075 10 2C10 1.44772 10.4477 1 11 1C11.5523 1 12 1.44772 12 2Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12 2C12 2.55228 11.5523 3 11 3C10.9231 3 10.8481 2.99131 10.7762 2.97485C10.1351 3.55549 9.42182 4.09176 8.66579 4.57984C8.6457 5.11442 8.20599 5.54163 7.6665 5.54163C7.50337 5.54163 7.34937 5.50257 7.21333 5.43329C6.59423 5.76439 5.96045 6.06504 5.32575 6.33342C5.26422 6.82669 4.84343 7.20837 4.3335 7.20837C4.08088 7.20837 3.85014 7.1147 3.67412 6.9602C3.09221 7.15624 2.5199 7.32304 1.96829 7.45916C1.85701 7.89007 1.46568 8.20837 1 8.20837C0.447715 8.20837 0 7.76066 0 7.20837C0 6.65609 0.447715 6.20837 1 6.20837C1.27306 6.20837 1.52056 6.31782 1.70102 6.49524C2.23509 6.36441 2.78931 6.20327 3.35246 6.0136C3.44308 5.55455 3.84786 5.20837 4.3335 5.20837C4.56067 5.20837 4.77014 5.28412 4.93805 5.41174C5.52014 5.16547 6.10067 4.8912 6.66769 4.59084C6.6669 4.57453 6.6665 4.55813 6.6665 4.54163C6.6665 3.98934 7.11422 3.54163 7.6665 3.54163C7.86077 3.54163 8.0421 3.59702 8.19556 3.69288C8.85683 3.26153 9.48017 2.79259 10.0426 2.28973C10.0149 2.19802 10 2.10075 10 2C10 1.44772 10.4477 1 11 1C11.5523 1 12 1.44772 12 2Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12 2C12 2.55228 11.5523 3 11 3C10.9231 3 10.8481 2.99131 10.7762 2.97485C10.1351 3.55549 9.42182 4.09176 8.66579 4.57984C8.6457 5.11442 8.20599 5.54163 7.6665 5.54163C7.50337 5.54163 7.34937 5.50257 7.21333 5.43329C6.59423 5.76439 5.96045 6.06504 5.32575 6.33342C5.26422 6.82669 4.84343 7.20837 4.3335 7.20837C4.08088 7.20837 3.85014 7.1147 3.67412 6.9602C3.09221 7.15624 2.5199 7.32304 1.96829 7.45916C1.85701 7.89007 1.46568 8.20837 1 8.20837C0.447715 8.20837 0 7.76066 0 7.20837C0 6.65609 0.447715 6.20837 1 6.20837C1.27306 6.20837 1.52056 6.31782 1.70102 6.49524C2.23509 6.36441 2.78931 6.20327 3.35246 6.0136C3.44308 5.55455 3.84786 5.20837 4.3335 5.20837C4.56067 5.20837 4.77014 5.28412 4.93805 5.41174C5.52014 5.16547 6.10067 4.8912 6.66769 4.59084C6.6669 4.57453 6.6665 4.55813 6.6665 4.54163C6.6665 3.98934 7.11422 3.54163 7.6665 3.54163C7.86077 3.54163 8.0421 3.59702 8.19556 3.69288C8.85683 3.26153 9.48017 2.79259 10.0426 2.28973C10.0149 2.19802 10 2.10075 10 2C10 1.44772 10.4477 1 11 1C11.5523 1 12 1.44772 12 2Z"></path>
                                </svg>
                            </span>
                            <span
                                className="px-2 py-1">
                                Increase
                            </span>
                        </div>
                        <div
                            className={"flex flex-row gap-1 dark:hover:text-blue hover:text-blue hover:bg-blue/10 dark:hover:bg-blue/30 rounded-md items-center pl-2"}
                            onClick={() => handleSelect(DistributionOption.Parabolic)}>
                            <span>
                                <svg viewBox="0 0 12 12" height="12" width="12" xmlns="http://www.w3.org/2000/svg" className="w-100 h-100">
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M8.33333 6.45837H7C6.91159 6.45837 6.82681 6.49349 6.7643 6.556C6.70179 6.61852 6.66667 6.7033 6.66667 6.79171V11.3334C6.66667 11.4218 6.70179 11.5066 6.7643 11.5691C6.82681 11.6316 6.91159 11.6667 7 11.6667H8.33333C8.42174 11.6667 8.50652 11.6316 8.56904 11.5691C8.63155 11.5066 8.66667 11.4218 8.66667 11.3334V6.79171C8.66667 6.7033 8.63155 6.61852 8.56904 6.556C8.50652 6.49349 8.42174 6.45837 8.33333 6.45837ZM11.6667 9.23309H10.3333C10.2449 9.23309 10.1601 9.26821 10.0976 9.33072C10.0351 9.39323 10 9.47802 10 9.56642V11.3334C10 11.4218 10.0351 11.5066 10.0976 11.5691C10.1601 11.6316 10.2449 11.6667 10.3333 11.6667H11.6667C11.7551 11.6667 11.8399 11.6316 11.9024 11.5691C11.9649 11.5066 12 11.4218 12 11.3334V9.56642C12 9.47802 11.9649 9.39323 11.9024 9.33072C11.8399 9.26821 11.7551 9.23309 11.6667 9.23309ZM5 6.45837H3.66667C3.57826 6.45837 3.49348 6.49349 3.43096 6.55601C3.36845 6.61852 3.33333 6.7033 3.33333 6.79171V11.3334C3.33333 11.4218 3.36845 11.5066 3.43096 11.5691C3.49348 11.6316 3.57826 11.6667 3.66667 11.6667H5C5.08841 11.6667 5.17319 11.6316 5.2357 11.5691C5.29821 11.5066 5.33333 11.4218 5.33333 11.3334V6.79171C5.33333 6.7033 5.29821 6.61852 5.2357 6.55601C5.17319 6.49349 5.08841 6.45837 5 6.45837ZM1.66667 9.13546H0.333333C0.244928 9.13546 0.160143 9.17058 0.0976311 9.23309C0.035119 9.2956 0 9.38039 0 9.46879L0 11.3334C0 11.4218 0.035119 11.5066 0.0976311 11.5691C0.160143 11.6316 0.244928 11.6667 0.333333 11.6667H1.66667C1.75507 11.6667 1.83986 11.6316 1.90237 11.5691C1.96488 11.5066 2 11.4218 2 11.3334V9.46879C2 9.38039 1.96488 9.2956 1.90237 9.23309C1.83986 9.17058 1.75507 9.13546 1.66667 9.13546Z"
                                          fill-opacity="0.4"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.70346 4.9956C3.30375 5.69437 2.82351 6.53394 1.9881 7.00885C1.91373 7.48767 1.49966 7.85413 1 7.85413C0.447715 7.85413 0 7.40641 0 6.85413C0 6.30184 0.447715 5.85413 1 5.85413C1.23206 5.85413 1.44566 5.93317 1.61534 6.06581C2.13027 5.71973 2.47796 5.11737 2.90185 4.38299L2.9328 4.32939C3.07347 4.08579 3.22459 3.82822 3.39629 3.57867C3.35569 3.46986 3.3335 3.35209 3.3335 3.22913C3.3335 2.67684 3.78121 2.22913 4.3335 2.22913C4.4947 2.22913 4.647 2.26727 4.78185 2.33503C5.12546 2.1819 5.52026 2.09375 5.97907 2.09375C6.45837 2.09375 6.87007 2.1794 7.22714 2.33057C7.35975 2.2656 7.50887 2.22913 7.6665 2.22913C8.21879 2.22913 8.6665 2.67684 8.6665 3.22913C8.6665 3.33624 8.64966 3.43941 8.61848 3.53616C8.80191 3.80273 8.95724 4.08002 9.09865 4.34174C9.14154 4.42111 9.18308 4.49886 9.22372 4.57491L9.22379 4.57504L9.22403 4.57549C9.45756 5.01254 9.66136 5.39395 9.91951 5.70896C10.048 5.86569 10.1846 5.99869 10.3385 6.10417C10.5148 5.94856 10.7464 5.85413 11 5.85413C11.5523 5.85413 12 6.30184 12 6.85413C12 7.40641 11.5523 7.85413 11 7.85413C10.5261 7.85413 10.1291 7.52442 10.026 7.08186C9.67104 6.89461 9.38528 6.63473 9.14605 6.3428C8.81987 5.94477 8.56326 5.46286 8.33303 5.0305L8.33289 5.03023L8.33277 5.03001C8.29413 4.95743 8.25623 4.88626 8.21887 4.81712C8.10055 4.59815 7.98546 4.39456 7.86552 4.20932C7.8012 4.22231 7.73465 4.22913 7.6665 4.22913C7.11422 4.22913 6.6665 3.78141 6.6665 3.22913C6.6665 3.21582 6.66677 3.20257 6.66728 3.18939C6.47 3.12823 6.24366 3.09375 5.97907 3.09375C5.73248 3.09375 5.5201 3.12893 5.33283 3.19223C5.33327 3.20447 5.3335 3.21677 5.3335 3.22913C5.3335 3.78141 4.88578 4.22913 4.3335 4.22913C4.27866 4.22913 4.22486 4.22471 4.17242 4.21622C4.0481 4.40329 3.92628 4.60868 3.79878 4.82946C3.76744 4.88374 3.73571 4.9392 3.70346 4.99558L3.70346 4.99559L3.70346 4.9956Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.70346 4.9956C3.30375 5.69437 2.82351 6.53394 1.9881 7.00885C1.91373 7.48767 1.49966 7.85413 1 7.85413C0.447715 7.85413 0 7.40641 0 6.85413C0 6.30184 0.447715 5.85413 1 5.85413C1.23206 5.85413 1.44566 5.93317 1.61534 6.06581C2.13027 5.71973 2.47796 5.11737 2.90185 4.38299L2.9328 4.32939C3.07347 4.08579 3.22459 3.82822 3.39629 3.57867C3.35569 3.46986 3.3335 3.35209 3.3335 3.22913C3.3335 2.67684 3.78121 2.22913 4.3335 2.22913C4.4947 2.22913 4.647 2.26727 4.78185 2.33503C5.12546 2.1819 5.52026 2.09375 5.97907 2.09375C6.45837 2.09375 6.87007 2.1794 7.22714 2.33057C7.35975 2.2656 7.50887 2.22913 7.6665 2.22913C8.21879 2.22913 8.6665 2.67684 8.6665 3.22913C8.6665 3.33624 8.64966 3.43941 8.61848 3.53616C8.80191 3.80273 8.95724 4.08002 9.09865 4.34174C9.14154 4.42111 9.18308 4.49886 9.22372 4.57491L9.22379 4.57504L9.22403 4.57549C9.45756 5.01254 9.66136 5.39395 9.91951 5.70896C10.048 5.86569 10.1846 5.99869 10.3385 6.10417C10.5148 5.94856 10.7464 5.85413 11 5.85413C11.5523 5.85413 12 6.30184 12 6.85413C12 7.40641 11.5523 7.85413 11 7.85413C10.5261 7.85413 10.1291 7.52442 10.026 7.08186C9.67104 6.89461 9.38528 6.63473 9.14605 6.3428C8.81987 5.94477 8.56326 5.46286 8.33303 5.0305L8.33289 5.03023L8.33277 5.03001C8.29413 4.95743 8.25623 4.88626 8.21887 4.81712C8.10055 4.59815 7.98546 4.39456 7.86552 4.20932C7.8012 4.22231 7.73465 4.22913 7.6665 4.22913C7.11422 4.22913 6.6665 3.78141 6.6665 3.22913C6.6665 3.21582 6.66677 3.20257 6.66728 3.18939C6.47 3.12823 6.24366 3.09375 5.97907 3.09375C5.73248 3.09375 5.5201 3.12893 5.33283 3.19223C5.33327 3.20447 5.3335 3.21677 5.3335 3.22913C5.3335 3.78141 4.88578 4.22913 4.3335 4.22913C4.27866 4.22913 4.22486 4.22471 4.17242 4.21622C4.0481 4.40329 3.92628 4.60868 3.79878 4.82946C3.76744 4.88374 3.73571 4.9392 3.70346 4.99558L3.70346 4.99559L3.70346 4.9956Z"></path>
                                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.70346 4.9956C3.30375 5.69437 2.82351 6.53394 1.9881 7.00885C1.91373 7.48767 1.49966 7.85413 1 7.85413C0.447715 7.85413 0 7.40641 0 6.85413C0 6.30184 0.447715 5.85413 1 5.85413C1.23206 5.85413 1.44566 5.93317 1.61534 6.06581C2.13027 5.71973 2.47796 5.11737 2.90185 4.38299L2.9328 4.32939C3.07347 4.08579 3.22459 3.82822 3.39629 3.57867C3.35569 3.46986 3.3335 3.35209 3.3335 3.22913C3.3335 2.67684 3.78121 2.22913 4.3335 2.22913C4.4947 2.22913 4.647 2.26727 4.78185 2.33503C5.12546 2.1819 5.52026 2.09375 5.97907 2.09375C6.45837 2.09375 6.87007 2.1794 7.22714 2.33057C7.35975 2.2656 7.50887 2.22913 7.6665 2.22913C8.21879 2.22913 8.6665 2.67684 8.6665 3.22913C8.6665 3.33624 8.64966 3.43941 8.61848 3.53616C8.80191 3.80273 8.95724 4.08002 9.09865 4.34174C9.14154 4.42111 9.18308 4.49886 9.22372 4.57491L9.22379 4.57504L9.22403 4.57549C9.45756 5.01254 9.66136 5.39395 9.91951 5.70896C10.048 5.86569 10.1846 5.99869 10.3385 6.10417C10.5148 5.94856 10.7464 5.85413 11 5.85413C11.5523 5.85413 12 6.30184 12 6.85413C12 7.40641 11.5523 7.85413 11 7.85413C10.5261 7.85413 10.1291 7.52442 10.026 7.08186C9.67104 6.89461 9.38528 6.63473 9.14605 6.3428C8.81987 5.94477 8.56326 5.46286 8.33303 5.0305L8.33289 5.03023L8.33277 5.03001C8.29413 4.95743 8.25623 4.88626 8.21887 4.81712C8.10055 4.59815 7.98546 4.39456 7.86552 4.20932C7.8012 4.22231 7.73465 4.22913 7.6665 4.22913C7.11422 4.22913 6.6665 3.78141 6.6665 3.22913C6.6665 3.21582 6.66677 3.20257 6.66728 3.18939C6.47 3.12823 6.24366 3.09375 5.97907 3.09375C5.73248 3.09375 5.5201 3.12893 5.33283 3.19223C5.33327 3.20447 5.3335 3.21677 5.3335 3.22913C5.3335 3.78141 4.88578 4.22913 4.3335 4.22913C4.27866 4.22913 4.22486 4.22471 4.17242 4.21622C4.0481 4.40329 3.92628 4.60868 3.79878 4.82946C3.76744 4.88374 3.73571 4.9392 3.70346 4.99558L3.70346 4.99559L3.70346 4.9956Z"></path>
                                </svg>
                            </span>
                            <span
                                className="px-2 py-1">
                                Parabolic
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InstallDistributionMenu;
