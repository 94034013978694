import React from 'react';

interface FooterProps {
    startYear: number;
}

export const Footer: React.FC<FooterProps> = ({ startYear }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-white/90 dark:bg-gray-dark/80 py-4 text-center text-gray-dark dark:text-white">
        <p className="text-sm text-gray-400">
            &copy; {startYear === currentYear ? currentYear : `${startYear} - ${currentYear}`} FedorFun. All rights reserved.
    </p>
    </footer>
);
};
