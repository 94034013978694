import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import ReactDOM from "react-dom";
import {ReactNode} from "react";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

interface PortalProps {
    children: ReactNode;
}

export function Portal({children}: PortalProps) {
    const el = document.getElementById('portal-root');
    if (!el) return null;
    return ReactDOM.createPortal(children, el);
}

export function getDateByLocale(value: string | number | Date | null | undefined, locale: string | undefined) {
    if (value) {
        const dateObj = new Date(value);

        const dateOptions: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'short'};
        const timeOptions: Intl.DateTimeFormatOptions = {hour: '2-digit', minute: '2-digit'};

        const localDateStr = dateObj.toLocaleDateString(locale === 'ua' ? 'uk' : locale, dateOptions);
        const localTimeStr = dateObj.toLocaleTimeString(locale === 'ua' ? 'uk' : locale, timeOptions);

        return `${localDateStr} ${localTimeStr}`;
    }
    return "Invalid date";
}

export function getDateFromString(value: string | null | undefined) {
    if (value) {
        try {
            return new Date(value);
        } catch (ignored) {
            return null;
        }
    }
    return null;
}

export function darkenColor(color: string, amount: number): string {
    // Удаляем возможные пробелы
    color = color.trim();

    // Конвертируем HEX в RGB
    if (color.startsWith('#')) {
        const bigint = parseInt(color.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return darkenRGB(r, g, b, amount);
    }

    // Если RGB
    if (color.startsWith('rgb')) {
        const match = color.match(/rgb(?:a)?\((\d+),\s*(\d+),\s*(\d+)/);
        if (match) {
            const [, r, g, b] = match.map(Number);
            return darkenRGB(r, g, b, amount);
        }
    }

    // Если HSL, уменьшаем Lightness
    if (color.startsWith('hsl')) {
        const match = color.match(/hsl(?:a)?\((\d+),\s*(\d+)%,\s*(\d+)%/);
        if (match) {
            const [, h, s, l] = match.map(Number);
            return `hsl(${h}, ${s}%, ${Math.max(0, l - amount)}%)`;
        }
    }

    // Вернуть исходный цвет, если он не подходит под обработку
    return color;
}

// Вспомогательная функция для работы с RGB
function darkenRGB(r: number, g: number, b: number, amount: number): string {
    const factor = 1 - amount / 100; // Рассчитываем уменьшение яркости
    const newR = Math.max(0, Math.min(255, Math.round(r * factor)));
    const newG = Math.max(0, Math.min(255, Math.round(g * factor)));
    const newB = Math.max(0, Math.min(255, Math.round(b * factor)));
    return `rgb(${newR}, ${newG}, ${newB})`;
}
