import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {Input} from "../../components/shared/Input";
import {ChevronRight, SearchIcon} from "lucide-react";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {transformJsonToUserDataListCount, UserDataList} from "../../lib/transform-json";
import {useNavigate} from "react-router-dom";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";

export default function UsersScene() {

    const {translate} = useLanguage()
    const navigate = useNavigate();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>('');
    const [data, setData] = useState<UserDataList>({totalCount: 0, list: []});

    const handleOpenUser = (uuid: string) => {
        navigate(`/users/detail?uuid=${uuid}`);
    }

    const loadUsers = () => {
        axiosPrivate.get(`/users/list?page=${currentPage}&size=${itemsPerPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}`).then((response) => {
            if (response.status === 200) {
                setData(transformJsonToUserDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadUsers();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadUsers();
    }, [search, sorted]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('users.list.title-page')}</span>

            <Card>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex flex-row justify-end"}>
                        <div className={"flex w-52"}>
                            <Input type={"search"}
                                   label={translate('def.search')}
                                   value={search}
                                   onChange={(e) => setSearch(e.target.value)}
                                   icon={<SearchIcon size={20}/>}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        <Table loading={loading} onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                            <TableHeader>
                                <TableColumnHeaderCell sortKey={"username"}>
                                    Username
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"email"}>
                                    E-mail
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"name"}>
                                    {translate('users.list.name')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"surname"}>
                                    {translate('users.list.surname')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('def.status')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('users.list.roles')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell className={"w-12"}>
                                    {translate('def.actions')}
                                </TableColumnHeaderCell>
                            </TableHeader>

                            <TableBody>
                                {data.list.map((item, index) => (
                                    <TableRow key={index} rowIndex={index}>
                                        <TableCell>
                                            <div className={"flex flex-row items-center justify-center gap-4"}>
                                                <Avatar className={`h-6 w-6 transition-all`}>
                                                    <AvatarFallback delayMs={500}>
                                                        {item.name?.charAt(0).toUpperCase()}{item.surname?.charAt(0).toUpperCase()}
                                                    </AvatarFallback>
                                                    <AvatarImage
                                                        src={item.imageSrc}
                                                    />
                                                </Avatar>
                                                {item.username}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {item.email}
                                        </TableCell>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.surname}
                                        </TableCell>
                                        <TableCell>
                                            <span className={item.status === 'BANNED' ? 'text-red' : ''}>{item.status}</span>
                                        </TableCell>
                                        <TableCell>
                                            {item.roles.map((role, index) => role).join(", ")}
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex w-full justify-center"}>
                                                <ChevronRight
                                                    className={"cursor-pointer bg-green/40 hover:bg-green/80 rounded-md p-1"}
                                                    onClick={() => handleOpenUser(item.uuid)}
                                                    size={20}/>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                        <TablePagination totalItems={data.totalCount}
                                         currentPage={currentPage}
                                         itemsPerPage={itemsPerPage}
                                         onChangeItemsPerPage={setItemsPerPage} onChangePage={setCurrentPage}/>
                    </div>
                </div>
            </Card>
        </div>
    )
}
