import {useModal} from "../../../hooks/use-modal-store";
import {ServerData, transformJsonToServerData} from "../../../lib/transform-json";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../../hooks/use-axios-private";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../../shared/dialog";
import {Button} from "../../shared/button";
import {useLanguage} from "../../../contexts/LanguageContext";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../shared/select";
import {DedikServer} from "./DedikServer";
import {RaspberryPiServer} from "./RaspberryPiServer";
import {Separator} from "../../shared/separator";
import {useToast} from "../../../contexts/ToastContext";
import {PhonesServer} from "./PhonesServer";

export const AddServerModal = () => {

    const {isOpen, onClose, type, data, callback} = useModal();

    const isModalOpen = isOpen && type === "add-server";

    const axiosPrivate = useAxiosPrivate();
    const {translate} = useLanguage();
    const {add} = useToast();

    const [serverTypes, setServerTypes] = useState<string[]>([]);
    const [server, setServer] = useState<ServerData>(
        {
            id: null,
            name: null,
            identifier: null,
            ip: null,
            type: 'RASPBERRY_PI',
            disks: [],
            server_params: {
                reboot: false,
                task_in_queue: null,
                time_to_bootloop: null,
                time_between_start: null,
                version_bot: null,
                version_script: null,
                update_link: null,
            },
        }
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Record<string, { value: string | undefined }>>({});

    const getTypes = () => {
        axiosPrivate.get('/servers/types').then((response) => {
            if (response.status === 200 && response.data.data) {
                setServerTypes(response.data.data.filter((type: string) => type !== 'PHONES'));
            }
        }).catch((error) => {
            console.log(error);
            onClose();
        });
    }

    const handleClose = () => {
        onClose();
        setError({});
    }

    const resetModel = () => {
        setServer({
            id: null,
            name: null,
            identifier: null,
            ip: null,
            type: 'RASPBERRY_PI',
            disks: [],
            server_params: {
                reboot: false,
                task_in_queue: null,
                time_to_bootloop: null,
                time_between_start: null,
                version_bot: null,
                version_script: null,
                update_link: null,
            }
        });
    }

    const handleSaveUpdate = () => {

        setError({});
        setLoading(true);
        let hasError = false;

        if (server.name === null || server.name === '') {
            hasError = true;
            setError(prev => ({...prev, name: {value: translate('servers.modal.error.name_require')}}));
        }

        if (server.type === 'DEDIK') {
            if (server.ip === null || server.ip === '') {
                hasError = true;
                setError(prev => ({...prev, ip: {value: translate('servers.modal.error.ip_require')}}));
            } else if (!/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(server.ip!!)) {
                hasError = true;
                setError(prev => ({...prev, ip: {value: translate('servers.modal.error.ip_invalid')}}));
            }

            if (server.server_params.update_link === null || server.server_params.update_link === '') {
                hasError = true;
                setError(prev => ({...prev, link_update: {value: translate('servers.modal.error.link_update_require')}}));
            } else if (!/^(http|https):\/\/[^ "]+$/.test(server.server_params.update_link!!)) {
                hasError = true;
                setError(prev => ({...prev, update_link: {value: translate('servers.modal.error.link_update_invalid')}}));
            }
        }

        if (server.type === 'RASPBERRY_PI') {
            if (server.identifier === null || server.identifier === '') {
                hasError = true;
                setError(prev => ({...prev, identifier: {value: translate('servers.modal.error.identifier_require')}}));
            }
        }

        if (server.disks.length === 0) {
            setError(prev => ({...prev, disks: {value: translate('servers.modal.error.one_disks_require')}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        //рлменять id disks на null если больше 10000
        let disks = server.disks.map((disk) => {
            if (disk.id && disk.id >= 10000) {
                return {
                    ...disk,
                    id: null
                }
            }
            return disk;
        });

        //формируем объект для отправки на сервер
        let serverDTO = {
            ...server,
            disks: disks
        }

        if (!serverDTO.id) {
            axiosPrivate.post('/servers/add', serverDTO)
                .then(response => {
                    if (response.status === 200) {
                        add({
                            type: "success",
                            message: "Server created",
                            duration: 2000,
                        })
                        callback && callback.onConfirm && callback.onConfirm();
                        resetModel();
                        onClose();
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                })
        } else {
            axiosPrivate.patch('/servers/update', serverDTO)
                .then(response => {
                    if (response.status === 200) {
                        add({
                            type: "success",
                            message: "Server updated",
                            duration: 2000,
                        })
                        callback && callback.onConfirm && callback.onConfirm();
                        resetModel();
                        onClose();
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if (isOpen) {
            getTypes();

            if (data && data.serverId) {
                setLoading(true);
                axiosPrivate.get(`/servers/?id=${data.serverId}`)
                    .then(response => {
                        if (response.status === 200 && response.data.data) {
                            setServer(response.data.data);
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false);
                    })
            }

            return () => {
            }
        } else if(server.id !== null) {
            resetModel();
            setError({});
        }
    }, [isOpen]);

    useEffect(() => {
        setServer(prevState => ({
            ...prevState,
            id: null,
            name: null,
            identifier: null,
            ip: null,
            disks: [],
            server_params: {
                reboot: false,
                task_in_queue: null,
                time_to_bootloop: null,
                time_between_start: null,
                version_bot: null,
                version_services: null,
                version_market: null,
                update_link: null
            }
        }));
        setError({});
    }, [server.type]);

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 overflow-scroll min-w-[700px] h-[500px]">
                <DialogHeader className="pt-4">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {server.name != null ? translate(server.name) : translate('servers.modal.add-title')}
                    </DialogTitle>
                </DialogHeader>

                <div className={"flex flex-col p-4"}>
                    <div className={"flex flex-col"}>
                        <span className={"text-gray-dark dark:text-white text-xs font-bold mb-1"}>{translate('servers.modal.type-server')}</span>
                        <Select
                            disabled={loading || server.id !== null}
                            value={server.type}
                            onValueChange={(value) => {
                                setServer(prev => ({...prev, type: value}));
                            }}
                        >
                            <SelectTrigger
                                className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                <SelectValue placeholder="Type Server"/>
                            </SelectTrigger>

                            <SelectContent className={"flex flex-col"}>
                                <div className={"h-fit overflow-scroll"}>
                                    {serverTypes.map((type) => (
                                        <SelectItem key={type} value={type}>
                                            {type}
                                        </SelectItem>
                                    ))}
                                </div>
                            </SelectContent>
                        </Select>
                    </div>
                    <Separator orientation={"horizontal"} className={"mt-2 mb-2"}/>
                    {server.type === 'DEDIK' && (
                        <DedikServer serverData={server} setForm={setServer} loading={loading} error={error}/>
                    )}
                    {server.type === 'RASPBERRY_PI' && (
                        <RaspberryPiServer serverData={server} setForm={setServer} loading={loading} error={error}/>
                    )}
                    {server.type === 'PHONES' && (
                        <PhonesServer serverData={server} setForm={setServer} loading={loading} error={error}/>
                    )}
                </div>

                <DialogFooter className="bg-gray-100 px-6 py-4 h-fit">
                    <Button className="h-10"
                            type={"button"}
                            onClick={handleSaveUpdate}
                            disabled={loading}>
                        {translate(server.id ? 'def.update' : 'def.save')}
                    </Button>
                    <Button className={"bg-red/30 hover:bg-red/80 transition-all"} onClick={handleClose}>
                        {translate("def.close")}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
