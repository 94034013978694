export const mockDataMachines = {
    "data": [{
        "name_server": "Amudya",
        "response": true,
        "active": [{
            "name": "2426_NelidaCarson_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "184_JohanneFarley_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2974_AbrahamHines_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "1584_RosendoMann_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2772_RobbiJohnston_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "1270_GaleStephenson_md3",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "658_ElroyAlvarado_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2782_ShaRobertson_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2780_DannWoods_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2779_TrentonMercer_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "43_ZofiaSutton_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2795_WynellClay_md3",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2783_DebbiColon_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2975_SamTran_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2976_ElijahChaney_md2",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2781_GavinRosa_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }],
        "active_recovery": [],
        "all": 2500,
        "disks": [
            {
                "name": "home",
                "count_machine": 500,
                "max_machine": 6
            }, {
                "name": "md2",
                "count_machine": 1000,
                "max_machine": 6
            }, {
                "name": "md3",
                "count_machine": 1000,
                "max_machine": 4
            }
        ]
    }, {
        "name_server": "Lenovo",
        "response": true,
        "active": [{
            "name": "3398_ManualOrtega_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "5206_TandyBranch_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "5141_JeanelleDixon_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "5211_TeresiaHowell_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }],
        "active_recovery": [],
        "all": 500,
        "disks": [{"name": "home", "count_machine": 500, "max_machine": 4}]
    }, {
        "name_server": "HopeAMD",
        "response": true,
        "active": [{
            "name": "713_LannySanford_md4",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "1962_KayleighBarr_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "1989_KieshaDuke_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "819_RachalBrooks_md4",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2104_HoraceCarrillo_md4",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2332_AleishaSavage_md2",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2764_ArnoldBaird_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2531_LeopoldoMunoz_md2",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2901_PageCole_md2",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2173_LanceMartin_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2898_KhalilahTerrell_md2",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2903_EdwinBartlett_md2",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2768_TobiTran_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2149_AlbertineBean_md4",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "225_JanuaryPage_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2420_DougJuarez_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2129_JohnsonCarney_md3",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2763_TammeraHolder_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2765_BeeBoyle_home",
            "android": "SNOW_CONE",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }, {
            "name": "2770_AdamAnderson_home",
            "android": "TIRAMISU",
            "status": "START",
            "recovery": false,
            "proxy": null,
            "language": null,
            "serial": null,
            "build": null,
            "imei": null
        }],
        "active_recovery": [],
        "all": 3500,
        "disks": [
            {
                "name": "home",
                "count_machine": 500,
                "max_machine": 6
            }, {
                "name": "md2",
                "count_machine": 1000,
                "max_machine": 6
            }, {
                "name": "md3",
                "count_machine": 1000,
                "max_machine": 4
            }, {
                "name": "md4",
                "count_machine": 1000,
                "max_machine": 4
            }]
    }],
    "date_update": "2023-10-27T12:55:39.166+00:00"
}
