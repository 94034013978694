import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";

const AccessDenied : React.FC = () => {

    const {translate} = useLanguage();
    
    return <div className="flex flex-col w-full items-center justify-items-center gap-4">
        <div className="overflow-hidden rounded-xl">
            <img src="/images/401.gif" alt="401" className="h-60"/>
        </div>

        <span className="text-4xl text-gray dark:text-white">{translate('error.access-denied')}</span>
    </div>
}

export default AccessDenied