import React from 'react';
import RequireAuth from "./components/auth/RequireAuth";
import MainLayout from "./components/layouts/MainLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import {AuthProvider} from "./contexts/AuthContext";
import {LanguageProvider} from "./contexts/LanguageContext";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {SidebarProvider} from "./contexts/SidebarContext";
import {ThemeProvider} from "./contexts/ThemeContext";
import {ToastProvider} from './contexts/ToastContext';
import {ServerUnavailableLayout} from "./components/layouts/ServerUnavailableLayout";
import {NewUserLayout} from "./components/layouts/NewUserLayout";
import {BanScene} from "./scenes/error/BanScene";
import {WebSocketProvider} from "./contexts/WebSocketContext";

function App() {


    return (
        <div className="App">
            <AuthProvider>
                <LanguageProvider>
                    <ThemeProvider>
                        <ToastProvider>
                            <WebSocketProvider>
                                <Router>
                                    <SidebarProvider>
                                        <Routes>
                                            <Route element={<RequireAuth/>}>
                                                <Route path="/*" element={<MainLayout/>}/>
                                            </Route>
                                            <Route path="/auth" element={<AuthLayout/>}/>
                                            <Route path="/new-user" element={<NewUserLayout/>}/>
                                            <Route path="/ban-user" element={<BanScene/>}/>
                                            <Route path="/server-unavailable" element={<ServerUnavailableLayout/>}/>
                                        </Routes>
                                    </SidebarProvider>
                                </Router>
                            </WebSocketProvider>
                        </ToastProvider>
                    </ThemeProvider>
                </LanguageProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
