import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {Input} from "../shared/Input";
import {useToast} from "../../contexts/ToastContext";
import {ErrorData, LocaleData, SmsCountryData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {AxiosError} from "axios";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../shared/select";

export const AddSmsServiceModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "sms-service-add";

    const {add} = useToast();
    const {translate} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});

    const [errorResponse, setErrorResponse] = useState<string>('');

    const [form, setForm] = useState<any>({
        locale: {},
        service: {},
        is_active: false
    });

    const handlerAddLocale = () => {

        setError({});
        setErrorResponse('');
        setLoading(true);
        let hasError = false;

        // if (form.language === '') {
        //     setError(prev => ({...prev, language: {value: 'language_is_require'}}));
        //     hasError = true;
        // }
        //
        // if (form.country === '') {
        //     setError(prev => ({...prev, country: {value: 'country_is_require'}}));
        //     hasError = true;
        // }

        if (hasError) {
            setLoading(false);
            return;
        }

        // axiosPrivate.put('/locales/', {
        //     type: form.type,
        //     language: form.language.toLowerCase(),
        //     country: form.country.toLowerCase(),
        // })
        //     .then((response) => {
        //         if (response.status === 200) {
        //             setForm({
        //                 id: 0,
        //                 language: '',
        //                 name: '',
        //                 country: '',
        //                 type: 'STANDARD',
        //             });
        //
        //             add({
        //                 type: "success",
        //                 message: "Locale added successfully",
        //             });
        //             setLoading(false);
        //             data.success = true;
        //             onClose();
        //         } else {
        //             setErrorResponse(response.data.error_message);
        //         }
        //         setLoading(false);
        //     })
        //     .catch((error) => {
        //         const errorAxios = error as AxiosError<ErrorData>;
        //         if (errorAxios.response?.status === 400) {
        //             setErrorResponse(error.response.data.error_message);
        //         } else
        //             console.log(error);
        //         setLoading(false);
        //     });
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        Add new locale
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    <div className="flex flex-col gap-2 p-6 mr-24">
                        <span className="text-xs font-bold text-red">{errorResponse}</span>
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handlerAddLocale} disabled={loading}>
                        Add
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
