import {SmsServiceData} from "../../lib/transform-json";
import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";

type SmsServiceItemProp = {
    data: SmsServiceData;
    onClick?: (service: SmsServiceData) => void;
}

const SmsServiceItem: React.FC<SmsServiceItemProp> = ({data, onClick}) => {

    const {translate} = useLanguage();

    return (
        <div
            onClick={() => onClick && onClick(data)}
            className="flex flex-col cursor-pointer rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border">
            <div
                className={`flex flex-row w-full bg-gray/10 justify-between items-center p-2`}>
                <div className={"flex flex-row gap-2 items-center"}>
                     <span
                         className={"text-xs text-gray-dark dark:text-white font-bold"}>{translate('sms-service.service.balance')} - {data.balance}</span>
                </div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>Service Type:</span>
                        <span
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1"}>{data.type}</span>
                    </div>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>Auth Param:</span>
                        <span
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1"}>Key - {data.api_key} Value: {data.api_host}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ItemSmsServiceCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
            </div>
        </div>
    )
}

export {SmsServiceItem, ItemSmsServiceCardLoader}
