import React from "react";
import {Star} from "lucide-react";
import {ActionTooltip} from "./tooltip";
import {cn} from "../../lib/utils";

type RateStarsProps = {
    value: number;
    onChange?: (value: number) => void;
    className?: string;
    countStars?: number;
};

export const RateStars: React.FC<RateStarsProps> = ({value, onChange, className, countStars = 5}) => {

    return (
        <div className={cn("flex flex-row gap-1", className)}>
            {Array(countStars).fill(0).map((_, index) => (
                <div className={"flex items-center justify-center"} key={index}>
                    {onChange ? (
                        <ActionTooltip key={`star-tooltip-${index}`} label={`${index + 1}`}>
                            <div
                                onClick={() => onChange && onChange(index + 1)}
                                className={`${index < value ? 'text-yellow' : 'text-gray'} cursor-pointer`}
                            >
                                <Star fill={index < value ? "#F1C40F" : '#777777'} size={16}/>
                            </div>
                        </ActionTooltip>
                    ) : (
                        <div className={`${index < value ? 'text-yellow' : 'text-gray'}`}>
                            <Star fill={index < value ? "#F1C40F" : '#777777'} size={16}/>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
