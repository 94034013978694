import React, {useEffect, useState} from "react";
import {useModal} from "../../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../../shared/dialog";
import {Button} from "../../shared/button";
import {useToast} from "../../../contexts/ToastContext";
import {ErrorData, LocaleData, transformJsonToLocaleDataList} from "../../../lib/transform-json";
import useAxiosPrivate from "../../../hooks/use-axios-private";
import {AxiosError} from "axios";
import {useLanguage} from "../../../contexts/LanguageContext";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../shared/select";
import {InfoIcon} from "lucide-react";
import {Separator} from "../../shared/separator";

export const EditAppLocaleModal = () => {
    const {isOpen, onClose, type, data, callback} = useModal();

    const isModalOpen = isOpen && type === "edit-app-locale";

    const {add} = useToast();
    const {translate} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [locales, setLocales] = useState<LocaleData[]>([]);

    const [errorResponse, setErrorResponse] = useState<string | null>(null);

    if (!data.appData && isModalOpen) {
        onClose();
    }

    const [app, setApp] = useState(data.appData || {
        app_id: '',
        locale: {
            id: -1,
            language: 'en',
            country: 'us',
        }
    });

    useEffect(() => {
        if (data.appData) {
            setApp(data.appData || {
                app_id: '',
                locale: {
                    id: -1,
                    language: 'en',
                    country: 'us',
                }
            });
        }
    }, [data.appData]);

    const handlerEditLocaleApp = () => {

        setLoading(true);

        const response = axiosPrivate.patch('/apps/updateLocale', app);

        response.then((response) => {
            if (response.status === 200) {
                add({
                    type: "success",
                    message: translate('apps.modal.edit-locale-success'),
                });
                setLoading(false);
                callback && callback.onConfirm && callback.onConfirm();
                onClose();
            } else {
                console.log(response.data);
            }
            setLoading(false);
        }).catch((error) => {
                const errorAxios = error as AxiosError<ErrorData>;

                if (errorAxios.response) {
                    if (errorAxios.response.status === 400) {
                        if (errorAxios.response.data.error_message)
                            setErrorResponse(errorAxios.response.data.error_message);
                        else
                            setErrorResponse("Error while adding app");
                    }
                } else {
                    console.log(error);
                }
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        if (isModalOpen) {
            axiosPrivate.get('/locales/list').then((response) => {
                if (response.status === 200 && response.data.data) {
                    const localesData = transformJsonToLocaleDataList(response.data.data)
                        .filter(locale => locale.type === 'STANDARD')
                        .sort((a, b) => a.name.localeCompare(b.name));

                    if (localesData.length === 0) {
                        add({type: "error", message: "No locale found"});
                        onClose();
                    }

                    setLocales(localesData);
                }
            }).catch((error) => {
                add({type: "error", message: "Error while fetching locales"});
                console.log(error);
                onClose();
            });
        }
    }, [isModalOpen]);

    const handlerSelectLocale = (value: string) => {
        const locale = locales.find(locale => locale.id.toString() === value);
        if (locale) {
            setApp(prev => ({...prev, locale: locale}));
        }
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('def.edit')} {app.app_id}
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    <div className="flex flex-col gap-2 p-6">
                        <span className="text-xs font-bold text-gray/50">Select default locale app</span>
                        <Select
                            disabled={loading}
                            required
                            value={app.locale?.id?.toString() || ''}
                            onValueChange={(value) => handlerSelectLocale(value)}
                        >

                            <SelectTrigger
                                className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                <SelectValue placeholder="Locale Select"/>
                            </SelectTrigger>

                            <SelectContent className={"flex flex-col"}>
                                <div className={"h-fit max-h-60 overflow-scroll"}>
                                    {locales.map((locale) => (
                                        <SelectItem
                                            key={locale.id.toString()}
                                            value={locale.id.toString()}
                                            className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                        >
                                            <div className={"flex flex-row gap-2"}>
                                                <img
                                                    className={"h-4 w-6 rounded-md overflow-hidden"}
                                                    alt={locale.country.toUpperCase()}
                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </div>
                            </SelectContent>
                        </Select>

                        {errorResponse && (
                            <>
                                <Separator orientation={"horizontal"}/>
                                <div className={"flex flex-row gap-1"}>
                                    <InfoIcon size={16} className="text-red"/>
                                    <span className="text-xs font-bold text-red">Error: {errorResponse}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button className={"bg-gray/50 hover:bg-gray"} onClick={handleClose} disabled={loading}>
                        {translate('def.cancel')}
                    </Button>
                    <Button onClick={handlerEditLocaleApp} disabled={loading}>
                        {translate('def.update')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
