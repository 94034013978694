import React, {useEffect, useState} from "react";
import {AgCharts} from 'ag-charts-react';
import {AG_CHARTS_LOCALE_UK_UA, AG_CHARTS_LOCALE_EN_US} from 'ag-charts-locale';
import {Loader} from "./loader";
import {useTheme} from "../../contexts/ThemeContext";
import type {AgChartOptions} from "ag-charts-community";
import {useLanguage} from "../../contexts/LanguageContext";
import {darkenColor} from "../../lib/utils";

export type ChartData = {
    data: LineData[],
    series: LineSeries[]
}

export type LineData = {
    dateTime: number,
    [key: string]: number
};

export type LineSeries = {
    type: string,
    xKey: string,
    yKey: string,
    yName: string,
    interpolation?: { type: string } | undefined,
    visible: boolean
}

type LineChartProps = {
    loading?: boolean
    chartData: ChartData | null,
    yName?: string,
    invertY?: boolean
    min?: number
    max?: number
    typeDate?: 'withHour' | 'withoutHour'
}

const LineChart: React.FC<LineChartProps> = (
    {
        loading = false,
        chartData,
        yName = 'Success',
        invertY = false,
        min = 0,
        max,
        typeDate = 'withHour'
    }
) => {
    const {mode} = useTheme();
    const {translate, language} = useLanguage();

    const getLocale = (key: string) => {
        switch (key) {
            case 'ua': {
                return AG_CHARTS_LOCALE_UK_UA;
            }
            default: {
                return AG_CHARTS_LOCALE_EN_US;
            }
        }
    }

    const setMax = () => {

        if (max) {
            return max;
        }

        if (!chartData || !chartData?.data) {
            return max ? max : 50;
        }
        let computeMax = Math.max(
            ...chartData.data.flatMap((item) =>
                Object.entries(item)
                    .filter(([key]) => key !== "dateTime") // Исключаем поле dateTime
                    .map(([_, value]) => value) // Берем только значения
            )
        );

        computeMax = computeMax * 1.3;

        if (computeMax < min) {
            computeMax = min + computeMax;
        }

        return computeMax;
    }

    function millisecondsToDateStrWithHour(milliseconds: number): string {
        const date = new Date(milliseconds);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');

        return `${day}.${month} ${hours}:00`;
    }

    function millisecondsToDateStr(milliseconds: number): string {
        const date = new Date(milliseconds);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    const [chartOptions, setChartOptions] = useState<AgChartOptions>({
        theme: mode === 'dark' ? 'ag-default-dark' : 'ag-default',
        background: {
            visible: false,
        },
        locale: {
            localeText: getLocale(language),
        },
        legend: {
            enabled: true,
            position: 'bottom',
        },
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    formatter: (params) => typeDate === 'withHour' ? millisecondsToDateStrWithHour(params.value) : millisecondsToDateStr(params.value),
                },
                gridLine: {
                    enabled: true,
                    style: [
                        {
                            lineDash: [5, 5],
                        },
                    ]
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: translate(yName),
                },
                gridLine: {
                    enabled: true,
                    style: [
                        {
                            lineDash: [5, 5],
                        },
                    ]
                },
                reverse: invertY,
                interval: {
                    maxSpacing: 40,
                },
                min: min,
                max: setMax(),
            },
        ],
    });

    function customTooltip(params: any): string {
        return (
            `
            <div class="bg-white dark:bg-gray dark:text-white rounded-md shadow-md overflow-hidden">
                <div class="flex flex-col">
                    <div class="p-2" style="background-color: ${mode === 'dark' ? darkenColor(params.color, 30) : darkenColor(params.color , -60)}">
                        <span class="text-sm font-bold">${params.title}</span>
                    </div>
                    <div class="flex flex-row gap-2 justify-between w-full min-w-32 p-2">
                        <span class="text-sm">${translate(yName)}</span>
                        <span class="text-sm font-bold">${params.datum[params.yKey]}</span>
                    </div>
                </div>
            </div>
            `
        );
    }

    useEffect(() => {

        const updateOptions = {
            ...chartOptions,
            theme: mode === 'dark' ? 'ag-material-dark' : 'ag-default',
            locale: {
                localeText: getLocale(language),
            },
            data: chartData ? chartData.data : [],
            series: chartData?.series ? chartData.series.map((series) => {
                return ({
                    type: series.type,
                    xKey: series.xKey,
                    yKey: series.yKey,
                    yName: series.yName,
                    interpolation: series.interpolation,
                    visible: series.visible,
                    tooltip: {
                        renderer: customTooltip,
                    },
                    marker: {
                        shape: 'circle',
                        size: 10
                    }
                });
            }) : [],
            axes: [
                {
                    type: 'category',
                    position: 'bottom',
                    label: {
                        formatter: (params: {
                            value: number
                        }) => typeDate === 'withHour' ? millisecondsToDateStrWithHour(params.value) : millisecondsToDateStr(params.value),
                    },
                    gridLine: {
                        enabled: true,
                        style: [
                            {
                                lineDash: [5, 5],
                            },
                        ]
                    },
                },
                {
                    type: 'number',
                    position: 'left',
                    title: {
                        text: translate(yName),
                    },
                    gridLine: {
                        enabled: true,
                        style: [
                            {
                                lineDash: [5, 5],
                            },
                        ]
                    },
                    interval: {
                        maxSpacing: 40,
                    },
                    reverse: invertY,
                    min: min,
                    max: setMax(),
                },
            ],
        }

        setChartOptions(updateOptions as AgChartOptions);
    }, [chartData, language, mode]);

    return (
        <div className="flex justify-center items-center w-full h-128 relative">
            <div className={"relative w-full h-full rounded-md p-1"}>
                {loading && (
                    <div className="absolute inset-0 flex justify-center items-center">
                        <Loader className={"z-10"}/>
                    </div>
                )}
                <AgCharts
                    style={{width: '100%', height: '100%', zIndex: 5}}
                    options={chartOptions}
                />
            </div>
        </div>
    )
}

export {LineChart}
