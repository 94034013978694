import {useLanguage} from "../../contexts/LanguageContext";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {transformJsonToUserData, UserData} from "../../lib/transform-json";
import {Card} from "../../components/shared/card";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {Button} from "../../components/shared/button";
import {useToast} from "../../contexts/ToastContext";
import {ActionTooltip} from "../../components/shared/tooltip";
import {userRoles} from "../../config/userRoles";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

export default function UserScene() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const uuid = searchParams.get('uuid');
    const navigate = useNavigate();

    const auth = useAuth();
    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {add} = useToast();

    const [data, setData] = useState<UserData | null>();

    const [loading, setLoading] = useState<boolean>(true);

    const handlerRole = (r: string) => {
        if (loading) return;
        if (data?.roles.includes(r)) {
            if (data?.roles.length === 1) {
                add({message: translate('users.detail.user-need-one-role'), type: 'error'});
                return;
            }
            axiosPrivate.patch(`/users/role/remove?uuid=${uuid}&role=${r.toUpperCase()}`).then((response) => {
                if (response.status === 200) {
                    data?.roles.splice(data?.roles.indexOf(r), 1);
                    setData(data);
                    add({message: translate('users.detail.role-removed'), type: 'success'});
                }
            }).catch((error) => {
                console.log(error);
                add({message: translate('users.detail.role-removed-error'), type: 'error'});
            });
        } else {
            axiosPrivate.patch(`/users/role/add?uuid=${uuid}&role=${r.toUpperCase()}`).then((response) => {
                if (response.status === 200) {
                    data?.roles.push(r);
                    setData(data);
                    add({message: translate('users.detail.role-added'), type: 'success'});
                }
            }).catch((error) => {
                console.log(error);
                add({message: translate('users.detail.role-add-error'), type: 'error'});
            });
        }
    }

    const handleButton = () => {
        if (loading) return;
        if (data?.status === 'NEW' || data?.status === 'BANNED') {
            axiosPrivate.patch(`/users/approve?uuid=${uuid}`).then((response) => {
                if (response.status === 200) {
                    setData(transformJsonToUserData(response.data.data));
                    add({message: translate('users.detail.user-approved'), type: 'success'});
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            axiosPrivate.patch(`/users/ban?uuid=${uuid}`).then((response) => {
                if (response.status === 200) {
                    setData(transformJsonToUserData(response.data.data));
                    add({message: translate('users.detail.user-banned'), type: 'success'});
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    useEffect(() => {

        axiosPrivate.get(`/users/?uuid=${uuid}`
        ).then((response) => {
            if (response.status === 200) {
                setData(transformJsonToUserData(response.data.data));
            } else {
                navigate('/not-found');
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            navigate('/not-found');
            setLoading(false);
        });
    }, [uuid, navigate]);


    return (
        <div className="flex flex-col gap-4">
            {loading ?
                <div className="h-7 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/> :
                <span className="text-2xl font-bold text-gray-dark dark:text-white capitalize">{data?.username}</span>
            }

            <Card>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex flex-row justify-between"}>
                        <div className="flex flex-row gap-4">
                            {loading ?
                                <div className="h-32 w-32 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/> :
                                <Avatar className={`h-32 w-32 transition-all`}>
                                    <AvatarFallback delayMs={100} style={{fontSize: '40px'}}>
                                        {`${data?.name.charAt(0).toUpperCase()}${data?.surname.charAt(0).toUpperCase()}`}
                                    </AvatarFallback>
                                    <AvatarImage
                                        src={data?.imageSrc}
                                    />
                                </Avatar>
                            }
                            <div className="flex flex-col gap-2">
                                <span className="text-gray">{translate('users.list.name')}:</span>
                                <span className="text-gray">{translate('users.list.surname')}:</span>
                                <span className="text-gray">{translate('auth.email')}:</span>
                            </div>
                            <div className="flex flex-col gap-2">
                                {!loading ?
                                    <span
                                        className="text-gray-dark dark:text-white font-bold capitalize">{data?.name}</span> :
                                    <div className="h-6 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/>
                                }
                                {!loading ?
                                    <span
                                        className="text-gray-dark dark:text-white font-bold capitalize">{data?.surname}</span> :
                                    <div className="h-6 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/>
                                }
                                {!loading ?
                                    <span className="text-gray-dark dark:text-white font-bold">{data?.email}</span> :
                                    <div className="h-6 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/>
                                }
                            </div>
                        </div>
                        {!loading && data?.username !== auth.user?.username &&
                            <Button
                                className={`${data?.status === 'ACTIVE' ? 'bg-red/30 hover:bg-red/50' : ''} h-10 w-32`}
                                onClick={handleButton}
                            >
                                {data?.status === 'NEW' || data?.status === 'BANNED' ? translate('users.detail.approve') : translate('users.detail.ban')}
                            </Button>
                        }
                    </div>
                    <div className={"flex flex-row gap-4"}>
                        <div className="flex flex-col gap-3">
                            <span className="text-gray">{translate('def.status')}:</span>
                            <span className="text-gray">{translate('users.list.roles')}:</span>
                        </div>
                        <div className="flex flex-col gap-3">
                            {!loading ?
                                <span
                                    className={`${data?.status === 'ACTIVE' ? 'text-green' : data?.status === 'BANNED' ? 'text-red' : 'text-gray-dark dark:text-white'} font-bold pl-1 capitalize`}>{data?.status}</span> :
                                <div className="h-6 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/>
                            }
                            {!loading ?
                                <div className={"flex flex-row gap-1"}>
                                    {data?.roles.map(r => (
                                        <ActionTooltip key={r} label={translate('users.detail.remove-role')}>
                                            <span
                                                onClick={() => handlerRole(r.toUpperCase())}
                                                className={`${"hover:bg-orange/40"} text-gray-dark dark:text-white font-bold p-1 rounded-md capitalize transition-all`}
                                            >
                                                {r}
                                            </span>
                                        </ActionTooltip>
                                    ))}
                                    {Object.values(userRoles).filter(role => !data?.roles.includes(role)).map(role => (
                                        <ActionTooltip key={"key_" + role} label={translate('users.detail.add-role')}>
                                            <span
                                                onClick={() => handlerRole(role.toUpperCase())}
                                                className={`${"hover:bg-green/40"} text-gray/50 font-bold p-1 rounded-md capitalize transition-all`}
                                            >
                                                {role}
                                            </span>
                                        </ActionTooltip>
                                    ))}
                                </div> :
                                <div className="h-6 w-44 bg-gray/30 dark:bg-gray/60 rounded-md animate-pulse"/>
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
