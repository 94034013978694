import React, {useState, useEffect} from 'react';

type SmsTimerProps = {
    endTime: number;
    endCallback?: () => void;
}

export const SmsTimer: React.FC<SmsTimerProps> = ({endTime, endCallback}) => {
    const [secondsRemaining, setSecondsRemaining] = useState(Math.floor((new Date(endTime).getTime() - new Date().getTime()) / 1000));

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsRemaining(prev => {
                if (prev <= 0) {
                    clearInterval(interval);
                    endCallback && endCallback();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return <div>{' to cancel - ' + formatTime(secondsRemaining)}</div>;
};