import axios, {axiosPrivate} from "../config/axios";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const navigate = useNavigate();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {

                if (error.response) {
                    const prevRequest = error?.config;

                    if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
                        prevRequest.sent = true;

                        const status = await refresh();

                        if (status) {
                            return axiosPrivate(prevRequest);
                        } else {
                            console.error('Redirecting to /auth due to failed token refresh');
                            navigate('/auth');
                        }
                    }
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [refresh, navigate])

    return axiosPrivate;
}

export const useRefreshToken = () => {
    return async () => {
        const response = await axios.get('/auth/refreshToken', {
            withCredentials: true
        });

        return response.status === 200;
    };
};

export default useAxiosPrivate;
