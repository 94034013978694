import React, {useEffect, useState} from "react";
import {ChevronUp} from "lucide-react";

export const ButtonToTop: React.FC = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            className={`fixed bottom-4 right-4 transition-all duration-300 ${
                isVisible ? "block" : "hidden"
            }`}
        >
            <button
                className="bg-blue/30 hover:bg-blue/60 text-white p-4 m-4 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue/40"
                onClick={scrollToTop}
            >
                <ChevronUp size={20}/>
            </button>
        </div>
    );
}