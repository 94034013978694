import TopBar from "../navigation/TopBar";
import {NewUser} from "../../scenes/error/NewUser";
import React from "react";

export const NewUserLayout: React.FC = () => {
    return (
        <div className="flex flex-col">
            <div className="absolute w-screen top-0">
                <TopBar/>
            </div>
            <NewUser/>
        </div>
    )
}