import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {Input} from "../../components/shared/Input";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {
    AppData,
    ErrorData,
    KeywordData,
    KeywordDataList,
    LocaleData,
    transformJsonToAppDataList,
    transformJsonToKeywordDataList,
    transformJsonToKeywordDataListCount,
    transformJsonToLocaleDataList
} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Button} from "../../components/shared/button";
import {Play, Plus, RefreshCw, SearchIcon, Star, Trash} from "lucide-react";
import {TabsContainer} from "../../components/shared/tabs-container";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {useToast} from "../../contexts/ToastContext";
import {useModal} from "../../hooks/use-modal-store";
import {AxiosError} from "axios";
import {useLocation} from "react-router";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {IMessage} from "@stomp/stompjs";
import {useAuth} from "../../contexts/AuthContext";
import {ChartData, LineChart, LineData} from "../../components/shared/ag-chart";
import {addDays} from "date-fns";
import DateRangePickerPopover from "../../components/shared/date-range-picker";

export default function KeywordsScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const auidParam = searchParams.get('auid') || '';
    const searchIdLocale = Number.parseInt(searchParams.get('locale_id') || '0', 0);

    const {isOpen, onOpen, data} = useModal();
    const {add} = useToast();
    const {getConnection, getConnectionStatus} = useWebSocket();
    const auth = useAuth();

    const [loading, setLoading] = useState({
        init: true,
        history: false,
        keys: false
    });

    const [responseApp, setResponseApp] = useState<AppData[]>([]);

    const [selectedApp, setSelectedApp] = useState<AppData | null>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>('');
    const [keyData, setKeyData] = useState<KeywordDataList>({totalCount: 0, list: []});

    const [selectedLocale, setSelectedLocale] = useState<LocaleData | null>(null);
    const [locales, setLocales] = useState<LocaleData[]>([]);

    const tabs = ["ALL", "FAVORITE"];
    const [tab, setTab] = useState<string>(tabs[0]);

    const [historyData, setHistoryData] = useState<ChartData | null>(null);
    const startDate = addDays(new Date(), -7);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [rangeDate, setRangeDate] = useState<{ startDate: Date, endDate: Date }>({
        startDate: startDate,
        endDate: endDate
    });

    const openAddAppModal = () => {
        onOpen(
            'add-app',
            {},
            {
                onAddedApp: (appData) => {
                    setResponseApp(prev => {
                        if (!prev.some(app => app.auid === appData.auid)) {
                            return [...prev, appData];
                        }
                        return prev;
                    });

                    setSelectedApp(appData);
                }
            }
        );
    }

    const handleDelete = (item: KeywordData) => {
        axiosPrivate.delete(`/keywords/delete?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    add({
                        message: translate('keyword-deleted'),
                        type: 'success'
                    });
                    setCurrentPage(1);
                    loadKeys();
                }
            }).catch((error) => {
            console.log(error);
        });
    }

    const handleFavorite = (index: number, item: KeywordData) => {
        axiosPrivate.patch(`/keywords/favorite?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    setKeyData(prevState => ({
                        ...prevState,
                        list: prevState.list.map((keyword, i) => {
                            if (i === index) {
                                return {
                                    ...keyword,
                                    favorite: !keyword.favorite
                                }
                            }
                            return keyword;
                        })
                    }));

                    loadHistoryPosition();
                }
            }).catch((error) => {
            console.log(error);
        });
    }

    const handleCheckPosition = (item: KeywordData) => {

        if (item.status === 'IN_CHECK') {
            add({message: translate('keywords.keyword-in-check'), type: 'info'});
            return;
        }

        axiosPrivate.post(`/keywords/check?kuid=${item.id}`)
            .then((response) => {
                if (response.status === 200) {
                    add({message: translate('keywords.success-start-checking') + ' ' + item.keyword, type: 'info'});
                }
            }).catch((error) => {

            const axiosError = error as AxiosError<ErrorData>

            if (axiosError.response) {
                if (axiosError.response.status === 400) {
                    add({message: axiosError.response.data?.error_message, type: 'error'});
                } else {
                    add({message: translate(`error.error-refresh-keyword`) + ` - ${item.keyword}`, type: 'error'});
                }
            } else {
                add({message: translate(`error.error-refresh-keyword `) + ` - ${item.keyword}`, type: 'error'});
            }
        });
    }

    const connectionStatus = getConnectionStatus('keywordsStatus');
    const onMessageReceived = (message: IMessage) => {

        if (message.body === '' || message.body === '[]') {
            return;
        }

        const dataKeywords = transformJsonToKeywordDataList(JSON.parse(message.body));

        if (dataKeywords.length === 0) {
            return;
        }

        setKeyData(prevState => ({
            ...prevState,
            list: prevState.list.map((keyword) => {
                const updateKeyword = dataKeywords.find((newKeyword) => newKeyword.id === keyword.id);
                if (updateKeyword) {
                    setHistoryData((prevHistoryData) => {
                        if (prevHistoryData) {
                            //TODO Переделать так е на поиск текущего дня
                            const findKey = prevHistoryData.series.find(
                                (series) => series.yName === updateKeyword.keyword
                            );

                            if (findKey) {
                                const lastDataPoint = prevHistoryData.data[prevHistoryData.data.length - 1];
                                if (lastDataPoint) {
                                    // Создаём копию последнего объекта
                                    const updatedDataPoint = {
                                        ...lastDataPoint,
                                        [findKey.yKey]: updateKeyword.position === 0 ? 250 : updateKeyword.position,
                                    };

                                    return {
                                        ...prevHistoryData,
                                        data: [
                                            ...prevHistoryData.data.slice(0, -1), // Добавляем все элементы, кроме последнего
                                            updatedDataPoint, // Обновляем последний элемент
                                        ],
                                    };
                                }
                            } else {
                                //TODO Добавлять новый ключ в график с новым днем
                            }
                        }
                        return prevHistoryData;
                    });

                    return updateKeyword;
                }
                return keyword;
            }),
        }));

    };

    useEffect(() => {
        const connection = getConnection('keywordsStatus');

        if (auth.user?.username && connection?.connected) {
            const subscription = connection.subscribe(`/user/${auth.user.username}/queue/keywordsStatus`, onMessageReceived);
            connection.publish({destination: `/ws/keywordsStatusStart`, body: JSON.stringify({start: true})});

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [auth.user?.username, getConnection, connectionStatus]);

    const loadKeys = () => {
        if (selectedLocale !== null && selectedApp !== null) {
            axiosPrivate.get(`/keywords/list?auid=${selectedApp.auid}&locale_id=${selectedLocale.id}&page=${currentPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}&size=${itemsPerPage}&favorite=${tab === 'FAVORITE'}`)
                .then((response) => {
                    if (response.status === 200 && response.data.data) {
                        setKeyData(transformJsonToKeywordDataListCount(response.data.data));
                        setLoading({
                            ...loading,
                            keys: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading({
                        ...loading,
                        keys: false
                    });
                });
        }
    }

    const loadHistoryPosition = () => {
        if (selectedApp === null) {
            return;
        }

        setLoading(
            {
                ...loading,
                history: true
            }
        );

        axiosPrivate.post(`/keywords/history`,
            {
                auid: selectedApp.auid,
                locale_id: selectedLocale?.id,
                start_date: rangeDate.startDate,
                end_date: rangeDate.endDate,
            })
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    let responseDataCategories = response.data.data.categories;
                    let responseDataSeries = response.data.data.series;

                    const keyMap = new Map<string, string>();

                    const generateKey = (originalKey: string): string => {
                        // Если ключ уже существует, возвращаем его
                        if (keyMap.has(originalKey)) {
                            return keyMap.get(originalKey)!;
                        }

                        // Если ключа нет, создаём новый
                        let counter = 1;
                        let uniqueKey = `key_${counter}`;

                        while (Array.from(keyMap.values()).includes(uniqueKey)) {
                            counter++;
                            uniqueKey = `key_${counter}`;
                        }

                        // Сохраняем новый ключ в Map
                        keyMap.set(originalKey, uniqueKey);

                        return uniqueKey;
                    };

                    let result: ChartData = {
                        data: [],
                        series: []
                    }

                    responseDataCategories.forEach((time: number) => {
                        let existingItem = result.data.find((item: LineData) => item.dateTime === time);

                        if (!existingItem) {
                            result.data.push({dateTime: time});
                            existingItem = result.data[result.data.length - 1]; // Получаем только что добавленный элемент
                        }

                        Object.keys(responseDataSeries).forEach((key) => {
                            const uniqueKey = generateKey(key);

                            // Извлекаем значение
                            const value = responseDataSeries[key].find(
                                (v: { time_check: number }) => v.time_check === time
                            )?.value;

                            // Если значение отсутствует или меньше либо равно нулю, присваиваем 250
                            existingItem!![uniqueKey] = value != null && value > 0 ? value : 250;
                        });
                    });

                    result.data.forEach((item) => {

                        //Добавляем серии
                        Object.keys(item).forEach((key) => {
                            if (key !== "dateTime") {
                                let existingSeries = result.series.find((series) => series.yKey === key);

                                if (!existingSeries) {

                                    let originalName = Array.from(keyMap.entries()).find(([_, value]) => value === key)?.[0] || key;

                                    result.series.push({
                                        type: 'line',
                                        xKey: 'dateTime',
                                        yKey: key,
                                        yName: originalName,
                                        visible: true,
                                    });
                                }
                            }
                        });
                    });

                    // Устанавливаем данные
                    setHistoryData(result);
                }

                setLoading(
                    {
                        ...loading,
                        history: false
                    }
                );
            })
            .catch((error) => {
                setHistoryData(null);
                console.log(error);
                setLoading(
                    {
                        ...loading,
                        history: false
                    }
                );
            });
    }

    useEffect(() => {
        loadHistoryPosition();
    }, [axiosPrivate, rangeDate, selectedApp, selectedLocale]);

    useEffect(() => {
        if (selectedApp === null) return;
        setLoading({
            ...loading,
            keys: true,
            history: true
        });
        if (selectedApp.locale !== null) {
            setSelectedLocale(locales.find(locale => locale.id === selectedApp.locale?.id) || null);
        }
        loadKeys();
        setCurrentPage(1);
    }, [selectedApp]);

    useEffect(() => {
        if (selectedApp === null) return;
        loadKeys();
        setCurrentPage(1);
    }, [tab, selectedLocale]);

    useEffect(() => {
        if (selectedApp === null) return;
        loadKeys();
    }, [search, sorted, itemsPerPage, currentPage]);

    useEffect(() => {
        if (!isOpen && data.success && selectedApp !== null) {
            loadKeys();
        }
    }, [isOpen, data]);

    const loadApps = () => {
        axiosPrivate.get('/apps/list')
            .then((response) => {
                if (response.status === 200) {
                    let appData = transformJsonToAppDataList(response.data.data).filter(app => app.status === 'ACTIVE');
                    setResponseApp(appData);
                    let appSearch = appData.find(app => app.auid === auidParam);
                    setSelectedApp(appSearch || null);
                }
                setLoading({
                    ...loading,
                    init: false
                });
            }).catch((error) => {
            setLoading({
                ...loading,
                init: false
            });
            console.log(error);
        });
    }

    const loadData = () => {
        setLoading({
            ...loading,
            init: true
        });

        axiosPrivate.get('/locales/list').then((response) => {
            if (response.status === 200) {
                let localeData = transformJsonToLocaleDataList(response.data.data)
                    .filter(locale => locale.type === 'STANDARD')
                    .sort((a, _) => a.country === 'us' ? -1 : 1);
                setLocales(localeData);
                setSelectedLocale(localeData[0]);
                loadApps();
            } else {
                add({type: 'error', message: 'Error load task types'});
            }
        }).catch((error) => {
            console.log(error);
            add({type: 'error', message: 'Error load task types'});
        });
    }

    //Load firs on open page
    useEffect(() => {
        loadData();

        return () => {

        }
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('keywords.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between"}>
                    <div className={"flex flex-row gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <div className={"flex flex-row items-center gap-4"}>
                                <div className={"w-96"}>
                                    <Select
                                        disabled={loading.init}
                                        value={selectedApp?.auid}
                                        onValueChange={(value) => setSelectedApp(responseApp.find(app => app.auid === value) || null)}
                                    >
                                        <SelectTrigger
                                            className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                            <SelectValue placeholder="Select Application"/>
                                        </SelectTrigger>

                                        <SelectContent className={"flex flex-col"}>
                                            <div className={"h-fit max-h-60 overflow-scroll"}>
                                                {Object.values(responseApp).map((app) => (
                                                    <SelectItem
                                                        key={app.auid}
                                                        value={app.auid}
                                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                    >
                                                        <div className={"flex flex-row gap-2"}>
                                                            <Avatar className={`h-8 w-8 transition-all`}>
                                                                <AvatarFallback delayMs={500}>
                                                                    I
                                                                </AvatarFallback>
                                                                <AvatarImage
                                                                    src={app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                        app.details[selectedLocale?.country + '_' + selectedLocale?.language].image_src :
                                                                        app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                            app.details[app.locale?.country + '_' + app.locale?.language].image_src :
                                                                            ''}
                                                                />
                                                            </Avatar>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].name :
                                                                    app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                        app.details[app.locale?.country + '_' + app.locale?.language].name :
                                                                        'Undefined'}
                                                            </span>
                                                                <span
                                                                    className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].publisher :
                                                                    app.details[app.locale?.country + '_' + app.locale?.language] ?
                                                                        app.details[app.locale?.country + '_' + app.locale?.language].publisher :
                                                                        'Undefined'}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </div>
                                        </SelectContent>
                                    </Select>
                                    <span className={"text-xs text-gray-dark dark:text-white"}>
                                        {translate('keywords.select-app')}
                                        <span className={"text-xs cursor-pointer text-green/50 hover:text-green hover:font-bold transition-all"}
                                              onClick={() => openAddAppModal()}> {translate('keywords.add-app-here')}
                                            </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-1"}>
                            <div className={"flex flex-row items-center gap-4"}>
                                <div className={"w-52"}>
                                    <Select
                                        disabled={loading.init}
                                        value={selectedLocale?.country + '_' + selectedLocale?.language}
                                        onValueChange={(value) => setSelectedLocale(locales.find(locale => locale.country + '_' + locale.language === value)!!)}
                                    >
                                        <SelectTrigger
                                            className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                            <SelectValue placeholder="Select Application"/>
                                        </SelectTrigger>

                                        <SelectContent className={"flex flex-col"}>
                                            <div className={"h-fit max-h-60 overflow-scroll"}>
                                                {Object.values(locales).map((locale) => (
                                                    <SelectItem
                                                        key={locale.name}
                                                        value={locale.country + '_' + locale.language}
                                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                    >
                                                        <div className={"flex flex-row gap-2"}>
                                                            <img
                                                                className={"h-4 w-6 rounded-md overflow-hidden"}
                                                                alt={locale.country.toUpperCase()}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                            <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </div>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex justify-center items-center"}>
                        <Button disabled={selectedApp === null} onClick={() => onOpen('add-keywords', {appData: selectedApp!!, localeData: selectedLocale!!})}>
                            <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                <Plus size={18}/>
                                <span className={"text-xs"}>{translate('keywords.add-keywords')}</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </Card>

            {selectedApp !== null && (
                <div className={"flex flex-col gap-2"}>
                    <Card>
                        <div>
                            <div className={"flex flex-col gap-6"}>
                                <div className={"flex flex-col gap-1"}>
                                    <div className={"flex flex-row items-center gap-4"}>
                                        <DateRangePickerPopover
                                            initDates={rangeDate}
                                            onApply={(start, end) => setRangeDate({startDate: start, endDate: end})}
                                        />
                                    </div>
                                </div>
                                <LineChart
                                    chartData={historyData}
                                    loading={loading.history}
                                    invertY={true}
                                    min={1}
                                    max={250}
                                    yName={'def.position'}
                                    typeDate={'withoutHour'}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex flex-row justify-end"}>
                                <div className={"flex w-52"}>
                                    <Input type={"text"}
                                           label={translate('def.search')}
                                           value={search}
                                           disable={loading.keys}
                                           onChange={(e) => setSearch(e.target.value)}
                                           icon={<SearchIcon size={20}/>}
                                    />
                                </div>
                            </div>

                            <div className={"flex w-full"}>
                                <TabsContainer classNameDivChildren={"flex flex-col gap-4 p-2"} tabs={tabs}
                                               onTabChange={setTab}>
                                    <Table loading={loading.keys}
                                           onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                                        <TableHeader>
                                            <TableColumnHeaderCell className={"w-12"}/>
                                            <TableColumnHeaderCell sortKey={"keyword"}>
                                                {translate('keywords.keyword')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell>
                                                {translate('def.locale')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell sortKey={"position"}>
                                                {translate('def.position')}
                                            </TableColumnHeaderCell>
                                            <TableColumnHeaderCell className={"w-24"}/>
                                        </TableHeader>

                                        <TableBody>
                                            {keyData.list.map((item, index) => (
                                                <TableRow key={index} rowIndex={index}>
                                                    <TableCell>
                                                        <ActionTooltip label={translate('keywords.save-favorite')}>
                                                            <div
                                                                className={`flex w-full justify-center ${item.favorite ? 'text-orange' : ''}`}
                                                                onClick={() => handleFavorite(index, item)}
                                                            >
                                                                <Star size={16}/>
                                                            </div>
                                                        </ActionTooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.keyword}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={"flex flex-row w-full justify-center gap-2"}>
                                                            <img
                                                                className={"h-4 w-4"}
                                                                alt={item.locale.name}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.locale.country.toUpperCase()}.svg`}/>
                                                            <span
                                                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{item.locale.name}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            className={"flex flex-row w-full justify-center gap-2 text-xs text-gray-dark dark:text-white"}>
                                                            <span className={item.position === 0 && item.status !== 'IN_CHECK' ? 'text-red' : ''}>
                                                                {item.position === 0 ? (item.status === 'IN_CHECK' ? translate('keywords.processing-check-keyword') : 'Out') : item.position}
                                                            </span>
                                                            {item.status === 'CHECKED' && item.position_diff !== 0 && (
                                                                <span
                                                                    className={`${item.position_diff > 0 ? 'text-green' : 'text-red'}`}>({item.position_diff > 0 ? '+' : ''}{item.position_diff})
                                                                </span>
                                                            )}
                                                            <RefreshCw
                                                                className={`cursor-pointer ${item.status === 'IN_CHECK' ? 'animate-[spin_1.5s_infinite]' : ''}`}
                                                                onClick={() => handleCheckPosition(item)} size={14}/>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={"flex flex-row justify-center gap-2 p-2"}>
                                                            <ActionTooltip label={translate('keywords.open-play-market')}>
                                                                <a
                                                                    rel={"noreferrer"}
                                                                    target={"_blank"}
                                                                    href={`https://play.google.com/store/search?c=apps&q=${item.keyword}&gl=${item.locale.country.toLowerCase()}&hl=${item.locale.language.toLowerCase()}-${item.locale.country.toUpperCase()}`}>
                                                                    <div className={"relative rounded-md p-1"}>
                                                                        <div
                                                                            className="absolute inset-0 bg-gradient-to-tr from-play-red to-play-blue rounded-md"></div>
                                                                        <div
                                                                            className="relative z-10 flex items-center justify-center w-full h-full">
                                                                            <Play size={14}
                                                                                  className="text-play-yellow"/>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </ActionTooltip>
                                                            <ActionTooltip label={translate('def.delete')}>
                                                                <div
                                                                    className={"p-1 text-gray-dark dark:text-white rounded-md bg-red/30 hover:bg-red/50 cursor-pointer transition-all"}
                                                                    onClick={() => handleDelete(item)}
                                                                >
                                                                    <Trash size={14}/>
                                                                </div>
                                                            </ActionTooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                    <TablePagination totalItems={keyData.totalCount}
                                                     currentPage={currentPage}
                                                     itemsPerPage={itemsPerPage}
                                                     onChangeItemsPerPage={setItemsPerPage}
                                                     onChangePage={setCurrentPage}/>
                                </TabsContainer>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </div>
    )
}
