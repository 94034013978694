import React, {createContext, useContext, useEffect, useState} from 'react';

interface ThemeProviderProps {
    children: React.ReactNode;
}

const ThemeContext = createContext<{
    mode: 'light' | 'dark';
    toggleTheme: () => void;
} | undefined>(undefined);

export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {

    // Проверяем, есть ли сохраненная тема в локальном хранилище
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';

    // Определяем начальную тему в зависимости от настроек пользователя и сохраненной темы
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const initialTheme = savedTheme || (prefersDarkMode ? 'dark' : 'light');

    const [mode, setMode] = useState<'light' | 'dark'>(initialTheme);

    useEffect(() => {
        if (mode === 'dark') {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        localStorage.setItem('theme', mode);
    }, [mode]);

    const toggleTheme = () => {
        setMode(prevMode => prevMode === 'dark' ? 'light' : 'dark');
    };

    return (
        <ThemeContext.Provider value={{mode, toggleTheme}}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
