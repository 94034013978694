import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {LoaderLayout} from "../layouts/LoaderLayout";
import {AxiosError} from "axios";
import {ErrorData, transformJsonToUserData} from "../../lib/transform-json";

const RequireAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const auth = useAuth();

    useEffect(() => {
        axiosPrivate.get('/auth/checkToken')
            .then(response => {
                setTimeout(() => {
                    if (response.status === 200 && response.data.data) {
                        auth.setUser(transformJsonToUserData(response.data.data));
                        setIsAuthenticated(true);
                    } else {
                        throw new Error("Error: response status is not 200");
                    }
                }, 1200);
            })
            .catch(error => {

                const errorAxios = error as AxiosError<ErrorData>;

                if (errorAxios.code === 'ERR_NETWORK') {
                    navigate("/server-unavailable", {replace: true});
                    return;
                }

                axiosPrivate.get('/auth/logout')
                    .then(r => {
                        auth.setUser(null);
                        auth.setApiKey(null);
                        setIsAuthenticated(false);
                    })
                    .catch(e => {
                        auth.setUser(null);
                        auth.setApiKey(null);
                        setIsAuthenticated(false);
                    });
            });
    }, [axiosPrivate]);

    if (isAuthenticated === null) {
        return <LoaderLayout/>;
    } else if (isAuthenticated) {
        if (auth?.user == null || auth?.user?.status == null || !auth?.user?.status) {
            console.log("Error: users or users.status is undefined");
            axiosPrivate.get('/auth/logout').then(r => console.log(r)).catch(e => console.log(e));
            auth.setUser(null);
            return <Navigate to="/auth" state={{from: location}} replace/>
        }

        if (auth?.user?.status === 'NEW') return <Navigate to="/new-user" state={{from: location}} replace/>;
        if (auth?.user?.status === 'BANNED') return <Navigate to="/ban-user" state={{from: location}} replace/>;

        return <Outlet/>;
    }

    return <Navigate to="/auth" state={{from: location}} replace/>;
}

export default RequireAuth;