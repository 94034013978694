import React, {useEffect, useState} from "react";
import {useModal} from "../../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../../shared/dialog";
import {Button} from "../../shared/button";
import {Input} from "../../shared/Input";
import {useToast} from "../../../contexts/ToastContext";
import {ErrorData, LocaleData, transformJsonToAppData, transformJsonToLocaleDataList} from "../../../lib/transform-json";
import useAxiosPrivate from "../../../hooks/use-axios-private";
import {AxiosError} from "axios";
import {useLanguage} from "../../../contexts/LanguageContext";
import useAppSearch from "../../../scenes/app/app-search";
import {Avatar, AvatarFallback, AvatarImage} from "../../shared/avatar";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../shared/select";
import {InfoIcon} from "lucide-react";
import {Separator} from "../../shared/separator";

export const AddAppModal = () => {
    const {isOpen, onClose, type, callback} = useModal();

    const isModalOpen = isOpen && type === "add-app";

    const {add} = useToast();
    const {translate} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [locales, setLocales] = useState<LocaleData[]>([]);

    const [error, setError] = useState<Record<string, { value: string }>>({});
    const [errorResponse, setErrorResponse] = useState<string | null>(null);
    const [form, setForm] = useState({
        appId: '',
        locale: {
            id: -1,
            language: 'en',
            country: 'us',
        }
    });

    const appInfo = useAppSearch(form.appId, form.locale?.country + '_' + form.locale?.language);

    const handlerAddApp = () => {

        setError({});
        setLoading(true);
        let hasError = false;

        if (form.appId === '') {
            setError(prev => ({...prev, appId: {value: 'appId_is_require'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        const response = axiosPrivate.put('/apps/add', {
            app_id: form.appId,
            locale: form.locale
        });

        response.then((response) => {
            if (response.status === 200) {

                if (response.data.data) {
                    const appData = transformJsonToAppData(response.data.data);
                    callback && callback.onAddedApp && callback.onAddedApp(appData);
                }

                setForm({
                    appId: '',
                    locale: {
                        id: -1,
                        language: 'en',
                        country: 'us',
                    }
                });

                add({
                    type: "success",
                    message: translate('apps.modal.add-success'),
                });
                setLoading(false);
                callback && callback.onConfirm && callback.onConfirm();
                onClose();
            } else {
                console.log(response.data);
            }
            setLoading(false);
        }).catch((error) => {
                const errorAxios = error as AxiosError<ErrorData>;

                if (errorAxios.response) {
                    if (errorAxios.response.status === 400) {
                        if (errorAxios.response.data.error_message)
                            setErrorResponse(errorAxios.response.data.error_message);
                        else
                            setErrorResponse("Error while adding app");
                    }
                } else {
                    console.log(error);
                }
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        if (isModalOpen) {
            axiosPrivate.get('/locales/list').then((response) => {
                if (response.status === 200 && response.data.data) {
                    const localesData = transformJsonToLocaleDataList(response.data.data)
                        .filter(locale => locale.type === 'STANDARD')
                        .sort((a, b) => a.name.localeCompare(b.name));

                    if (localesData.length === 0) {
                        add({type: "error", message: "No locale found"});
                        onClose();
                    }

                    setLocales(localesData);
                    setForm(prev => ({...prev, locale: localesData[0]}));
                }
            }).catch((error) => {
                add({type: "error", message: "Error while fetching locales"});
                console.log(error);
                onClose();
            });

            return () => {}
        }
    }, [isModalOpen]);

    const handlerSelectLocale = (value: string) => {
        const locale = locales.find(locale => locale.id.toString() === value);
        if (locale) {
            setForm(prev => ({...prev, locale: locale}));
        }
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('apps.modal.add-title')}
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    <div className="flex flex-col gap-2 p-6">
                        <span className="text-xs font-bold text-gray/50">Please enter application package (ex. com.facebook.katana) or app url</span>
                        <div className={"w-full"}>
                            <Input type={"text"}
                                   required
                                   label={"App Id"}
                                   disable={loading}
                                   value={form.appId}
                                   error={error.appId?.value}
                                   onChange={(e) => setForm(prev => ({...prev, appId: e.target.value}))}/>
                        </div>

                        <span className="text-xs font-bold text-gray/50">Select default locale app</span>
                        <Select
                            disabled={loading}
                            required
                            value={form.locale?.id?.toString() || ''}
                            onValueChange={(value) => handlerSelectLocale(value)}
                        >

                            <SelectTrigger
                                className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                <SelectValue placeholder="Locale Select"/>
                            </SelectTrigger>

                            <SelectContent className={"flex flex-col"}>
                                <div className={"h-fit max-h-60 overflow-scroll"}>
                                    {locales.map((locale) => (
                                        <SelectItem
                                            key={locale.id.toString()}
                                            value={locale.id.toString()}
                                            className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                        >
                                            <div className={"flex flex-row gap-2"}>
                                                <img
                                                    className={"h-4 w-6 rounded-md overflow-hidden"}
                                                    alt={locale.country.toUpperCase()}
                                                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </div>
                            </SelectContent>
                        </Select>

                        {appInfo === null ? (
                            <span className="text-xs font-bold text-red">{translate('apps.modal.preview-not-found')}</span>
                        ) : appInfo?.loading === false ? (
                            appInfo && (
                                <div className={"flex flex-col gap-1"}>
                                    <span className={"font-bold text-gray/50 text-xs"}>Preview: </span>
                                    <div className={"flex flex-row gap-2"}>
                                        <Avatar className={`h-8 w-8 transition-all`}>
                                            <AvatarFallback delayMs={500}>
                                                I
                                            </AvatarFallback>
                                            <AvatarImage
                                                src={appInfo.image}
                                            />
                                        </Avatar>
                                        <div className={"flex flex-col items-start justify-center text-gray-dark dark:text-white text-xs"}>
                                        <span className={"max-w-[200px] whitespace-nowrap truncate"}>
                                            {appInfo.name}
                                        </span>
                                            <span className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                            {appInfo.publisher}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <span className="text-xs font-bold text-gray/50">Loading...</span>
                        )}

                        {errorResponse && (
                            <>
                                <Separator orientation={"horizontal"}/>
                                <div className={"flex flex-row gap-1"}>
                                    <InfoIcon size={16} className="text-red"/>
                                    <span className="text-xs font-bold text-red">Error: {errorResponse}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button className={"bg-gray/50 hover:bg-gray"} onClick={handleClose} disabled={loading}>
                        {translate('def.cancel')}
                    </Button>
                    <Button onClick={handlerAddApp} disabled={loading}>
                        {translate('def.add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
