import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useModal} from "../../hooks/use-modal-store";
import {useLanguage} from "../../contexts/LanguageContext";

export const DetailDayModal = () => {

    const {isOpen, onClose, type, callback, data} = useModal();

    const isModalOpen = isOpen && type === "detail-day";

    const {translate} = useLanguage();

    if (!data.dayData) {
        return null;
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 overflow-hidden">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-2xl text-center font-bold text-gray-dark dark:text-white">
                        {data.dayData!!.day}
                    </DialogTitle>
                </DialogHeader>

                <div>

                </div>

                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handleClose}>
                        {translate("def.close")}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}