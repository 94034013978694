import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useToast} from "../../contexts/ToastContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../shared/separator";
import InputChips from "../shared/input-chips";
import {AlertCircle, ImportIcon, Info} from "lucide-react";

export const AddKeywordsModal = () => {
    const {isOpen, onClose, type, callback,  data} = useModal();

    const isModalOpen = isOpen && type === "add-keywords";

    const {add} = useToast();
    const {translate} = useLanguage();

    const [form, setForm] = useState({
        auid: '',
        keywords: [''],
        locale: {
            id: 0,
        }
    });

    if (isModalOpen) {
        if (!data.appData || !data.appData.app_id || !data.localeData) {
            add({
                type: "error",
                message: "keywords.modal.error-loading-app",
            });
            onClose();
        } else {
            form.locale.id = data.localeData.id;
            form.auid = data.appData.auid;
            form.locale = data.localeData!!;
        }
    }

    const maxKeywords = 500;
    const [importedKeywords, setImportedKeywords] = useState<string[]>([]);

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});
    const [errorResponse, setErrorResponse] = useState<string | null>(null);

    const handlerAddKeywords = () => {
        setError({});
        setLoading(true);
        let hasError = false;

        if (form.keywords.length === 0) {
            setError(prev => ({...prev, appId: {value: 'keywords.modal.one_keyword_is_required'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.post('/keywords/add', form).then((response) => {
            if (response.status === 200) {
                add({
                    type: "success",
                    message: translate('keywords.modal.success'),
                });
                callback && callback.onConfirm && callback.onConfirm(form.keywords);
                handleClose();
                setForm({
                    auid: '',
                    keywords: [''],
                    locale: {
                        id: 0,
                    }
                })
                data.success = true;
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const handleImportFromFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === 'string') {
                    let importedKeywords: string[] = [];

                    if (file.name.endsWith('.txt')) {
                        importedKeywords = text.split('\n').map(keyword => keyword.trim()).filter(Boolean);
                    } else if (file.name.endsWith('.csv')) {
                        importedKeywords = text.split(',').map(keyword => keyword.trim()).filter(Boolean);
                    }

                    setImportedKeywords(importedKeywords);
                }
            };
            reader.readAsText(file);
        }
    }

    useEffect(() => {
        setImportedKeywords([]);
    }, [form]);

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 min-w-[800px]">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('keywords.modal.title')}
                    </DialogTitle>
                    <Separator/>
                </DialogHeader>
                <div className="max-h-144 p-6">
                    <div className="flex flex-col gap-4">
                        <span className={"text-gray-dark dark:text-white text-sm"}>{translate('keywords.modal.description')}</span>
                        <InputChips
                            items={form.keywords}
                            importItems={importedKeywords}
                            maxItems={maxKeywords}
                            duplicate={false}
                            placeholder={''}
                            onChange={(value) => setForm(prev => ({...prev, keywords: value}))}
                            className={"min-h-[200px]"}/>

                        <div className={"flex flex-row justify-between"}>
                            <div>
                                <Button
                                    onClick={() => document.getElementById('fileInput')?.click()}
                                    disabled={loading}
                                    className={"bg-blue/50 hover:bg-blue/80 dark:bg-blue/30 dark:hover:bg-blue/50"}>
                                    <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                        <ImportIcon size={16}/>
                                        <span className={"text-xs"}>{translate('keywords.modal.import')}</span>
                                    </div>
                                </Button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".txt,.csv"
                                    onChange={handleImportFromFile}
                                    className="hidden"
                                />
                            </div>
                            <div className={"text-gray-dark dark:text-white flex flex-row gap-1 justify-center items-center"}>
                                <span className={"text-green"}>{form.keywords.length}</span>
                                <span>/</span>
                                <span>{maxKeywords}</span>
                            </div>
                        </div>

                        <div className={"flex flex-col items-start"}>
                            <div className={"flex flex-row gap-1 justify-center items-center"}>
                                <Info size={12} className={"text-gray"}/>
                                <span className={"text-xs text-gray"}>{translate('keywords.modal.add-keywords-1')}</span>
                            </div>
                            <div className={"flex flex-row gap-1 justify-center items-center"}>
                                <Info size={12} className={"text-red"}/>
                                <span className={"text-xs text-red"}>{translate('keywords.modal.add-keywords-2')}</span>
                            </div>

                            {error && (
                                <div className={"flex w-full flex-col gap-2 mt-2 justify-center"}>
                                    <Separator/>
                                    {Object.values(error).map((value, index) => (
                                        <div key={"errorDiv_" + index} className={"flex flex-row gap-2 text-red items-center"}>
                                            <AlertCircle size={14}/>
                                            <span key={`error_${index}`} className={"flex text-xs"}>{translate(value.value)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 p-4">
                    <Button className={"bg-gray/50 hover:bg-gray/90"} onClick={handleClose} disabled={loading}>
                        {translate('def.cancel')}
                    </Button>
                    <Button onClick={handlerAddKeywords} disabled={loading}>
                        {translate('def.add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
