import React from "react";
import {cn} from "../../lib/utils";

type ProgressLineProps = {
    progress: number;
    loading?: boolean;
    className?: string;
}

const ProgressLine: React.FC<ProgressLineProps> = ({progress, loading, className}) => {
    return (
        <div role={"status"} className={cn("flex flex-row w-full h-2 bg-gray/50 rounded-full", className, (loading ? 'animate-[pulse_1.5s_infinite]' : ''))}>
            <div className={`flex bg-green/60 h-2 rounded-full transition-all animation-delay-1000`} style={{width : `${loading ? '0' : progress}%`}}></div>
        </div>
    )
}

export {ProgressLine}