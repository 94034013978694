import React, {useEffect, useState} from "react";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Separator} from "../../components/shared/separator";
import {ProgressLine} from "../../components/shared/progress-line";
import {Card} from "../../components/shared/card";
import {getDateByLocale} from "../../lib/utils";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";

const PassiveData: React.FC = () => {

    const {translate, language} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const loadingDataInit = {
        init: true,
        refresh: false,
    }

    const [loading, setLoading] = useState(loadingDataInit);
    const [data, setData] = useState<any>({
        total: {
            total: 0,
            free: 0,
            active: 0,
            ban: 0,
            verify: 0,
        },
        by_locale: {},
        time_update: 0,
    });

    const loadData = () => {
        axiosPrivate.get(`/statistic/passiveData`).then((response) => {
            if (response.status === 200 && response.data.data) {
                setData(response.data.data);
            }
            setLoading({
                ...loading,
                init: false,
                refresh: false
            });
        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                init: false,
                refresh: false
            });
        });
    }

    const handleRefresh = () => {
        setLoading({
            ...loading,
            refresh: true
        });

        loadData();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Card>
            <div className={"flex flex-row w-full items-center justify-between"}>
                <span className={"font-bold text-gray-dark dark:text-white"}>{translate('dashboard.statistic.passive-data')}</span>

                <div className={"flex flex-row gap-1 items-center"}>
                    {loading.init || loading.refresh ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={translate('def.last-time-update')}>
                            <span className={"text-xs text-gray/50"}>{getDateByLocale(data.time_update, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                            onClick={handleRefresh}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.init || loading.refresh ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <Separator orientation={"horizontal"}/>

            <div className={"flex flex-col gap-2"}>
                <div className={"grid sm:grid-cols-2 lg:grid-cols-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-blue/80 text-xs font-bold"}>{translate('dashboard.statistic.total-accounts')}</span>
                        {loading.init || loading.refresh ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <div>
                                <ActionTooltip
                                    label={Object.entries(data.by_locale)
                                        .filter(([key, value]: [string, any]) => value.total > 0)
                                        .map(([key, value]: [string, any]) => {
                                            const nameMatch = key.match(/name=([^,]*)/);
                                            return (nameMatch ? nameMatch[1] : "Unknown") + " - " + value.total;
                                        })
                                        .join("\n")}
                                >
                                    <span className={"font-bold text-gray-dark dark:text-white"}>{data.total.total}</span>
                                </ActionTooltip>
                            </div>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-green/80 text-xs font-bold"}>{translate('dashboard.statistic.work-accounts')}</span>
                        {loading.init || loading.refresh ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <div>
                                <ActionTooltip
                                    label={Object.entries(data.by_locale)
                                        .filter(([key, value]: [string, any]) => value.active > 0)
                                        .map(([key, value]: [string, any]) => {
                                            const nameMatch = key.match(/name=([^,]*)/);
                                            return (nameMatch ? nameMatch[1] : "Unknown") + " - " + value.active;
                                        })
                                        .join("\n")}
                                >
                                    <span className={"font-bold text-gray-dark dark:text-white"}>{data.total.active}</span>
                                </ActionTooltip>
                            </div>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>{translate('dashboard.statistic.verify')}</span>
                        {loading.init || loading.refresh ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <div>
                                <ActionTooltip
                                    label={Object.entries(data.by_locale)
                                        .filter(([key, value]: [string, any]) => value.verify > 0)
                                        .map(([key, value]: [string, any]) => {
                                            const nameMatch = key.match(/name=([^,]*)/);
                                            return (nameMatch ? nameMatch[1] : "Unknown") + " - " + value.verify;
                                        })
                                        .join("\n")}
                                >
                                    <span className={"font-bold text-gray-dark dark:text-white"}>{data.total.verify}</span>
                                </ActionTooltip>
                            </div>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-red/80 text-xs font-bold"}>{translate('dashboard.statistic.ban')}</span>
                        {loading.init || loading.refresh ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <div>
                                <ActionTooltip
                                    label={Object.entries(data.by_locale)
                                        .filter(([key, value]: [string, any]) => value.ban > 0)
                                        .map(([key, value]: [string, any]) => {
                                            const nameMatch = key.match(/name=([^,]*)/);
                                            return (nameMatch ? nameMatch[1] : "Unknown") + " - " + value.ban;
                                        })
                                        .join("\n")}
                                >
                                    <span className={"font-bold text-gray-dark dark:text-white"}>{data.total.ban}</span>
                                </ActionTooltip>
                            </div>
                        }
                    </div>
                </div>
                {/*<div className={"px-2"}>*/}
                {/*    <Separator orientation={"horizontal"}/>*/}
                {/*</div>*/}
                {/*<div className={"grid sm:grid-cols-2 lg:grid-cols-4"}>*/}
                {/*    <div className={"flex flex-col gap-1"}>*/}
                {/*        <span className={"text-orange/80 text-xs font-bold"}>B-Loop</span>*/}
                {/*        {loading.init ?*/}
                {/*            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :*/}
                {/*            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className={"flex flex-col gap-1"}>*/}
                {/*        <span className={"text-orange/80 text-xs font-bold"}>Wrong Sync</span>*/}
                {/*        {loading.init ?*/}
                {/*            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :*/}
                {/*            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className={"flex flex-col gap-1"}>*/}
                {/*        <span className={"text-orange/80 text-xs font-bold"}>Wrong Machine</span>*/}
                {/*        {loading.init ?*/}
                {/*            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :*/}
                {/*            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className={"flex flex-col gap-1"}>*/}
                {/*        <span className={"text-orange/80 text-xs font-bold"}>Wrong Build</span>*/}
                {/*        {loading.init ?*/}
                {/*            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :*/}
                {/*            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={"px-2"}>
                    <Separator orientation={"horizontal"}/>
                </div>
                <div className={"flex flex-col gap-1"}>
                    <div className={"flex flex-row"}>
                        <ActionTooltip label={"Count free account"}>
                            <span className={"text-xs text-gray/60"}>{translate('dashboard.statistic.free-accounts')}</span>
                        </ActionTooltip>
                    </div>
                    <div className={"flex flex-row justify-between gap-2"}>
                        <div className={"flex justify-start items-center w-12"}>
                            {loading.init || loading.refresh ?
                                <div className={"w-12 h-3.5 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                                <span className={"text-gray-dark dark:text-white text-xs"}>{(data.total.free / data.total.total * 100).toFixed(2)}%</span>
                            }
                        </div>

                        <div className={"flex w-full items-center"}>
                            <ProgressLine progress={(data.total.free / data.total.total * 100)} loading={loading.init}/>
                        </div>
                        <div className={"flex justify-center items-center w-12"}>
                            {loading.init || loading.refresh ?
                                <div className={"w-12 h-3.5 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                                <div>
                                    <ActionTooltip
                                        label={Object.entries(data.by_locale)
                                            .filter(([key, value]: [string, any]) => value.free > 0)
                                            .map(([key, value]: [string, any]) => {
                                                const nameMatch = key.match(/name=([^,]*)/);
                                                return (nameMatch ? nameMatch[1] : "Unknown") + " - " + value.free;
                                            })
                                            .join("\n")}
                                    >
                                        <span className={"font-bold text-gray-dark dark:text-white text-xs"}>{data.total.free}</span>
                                    </ActionTooltip>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Card>
    )
}
export {PassiveData}
