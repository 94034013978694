import React from "react";
import {cn} from "../../lib/utils";

type ButtonProps = {
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export const Button: React.FC<ButtonProps> = ({children, disabled = false, className, ...props}) => {
    return (
        <button disabled={disabled}
                className={cn('bg-green/50 hover:bg-green/90 text-white font-bold text-sm whitespace-nowrap py-2 px-4 disabled:bg-gray rounded-md transition-all', className)} {...props}>
            {children}
        </button>
    );
}