import React from "react";
import {Button} from "../../components/shared/button";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";

export const BanScene: React.FC = () => {

    const axiosPrivate = useAxiosPrivate();
    const auth = useAuth();
    const navigate = useNavigate();

    const {translate} = useLanguage();

    const handlerLogout = () => {
        axiosPrivate.get('/auth/logout')
            .then(r => {
                auth.setUser(null);
                navigate("/auth", {replace: true});
            })
            .catch(e => {
                auth.setUser(null);
                navigate("/auth", {replace: true});
            });
    }

    return (
        <div className="flex flex-col w-screen h-screen bg-main-dark items-center justify-center">
            <div className={"flex flex-col gap-2 items-center"}>
                <span className={"text-2xl font-bold text-red"}>{translate('error.you-banned')}</span>

                <Button className={"w-36"} onClick={handlerLogout}>{translate('auth.logout')}</Button>
            </div>
        </div>
    )
}