import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {AccountData, transformJsonToAccountData} from "../../lib/transform-json";
import {Card} from "../../components/shared/card";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../../components/shared/separator";
import {getDateByLocale} from "../../lib/utils";
import {ItemAppCard} from "../app/app-item";

export const AccountDetailScene: React.FC = () => {

    const axiosPrivate = useAxiosPrivate();

    const {translate, language} = useLanguage();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const login = searchParams.get('login') || '';

    const [data, setData] = useState<AccountData | null>(null);

    const loadAccount = () => {
        axiosPrivate.get(`/accounts/?login=${login}`)
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    setData(transformJsonToAccountData(response.data.data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        loadAccount();
    }, [axiosPrivate]);

    return (
        <div className="flex flex-col gap-4">

            <div className={"flex flex-row justify-between"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('accounts.detail.title-page')}</span>
            </div>

            <Card>
                <div className={"w-full grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4 text-gray-dark dark:text-white"}>
                    <div className={"flex flex-col"}>
                        <span className={"text-lg font-bold"}>{translate('accounts.detail.account-data')}</span>
                        <Separator orientation={"horizontal"}/>
                        <div className={"flex flex-col gap-2"}>
                            <span className={"text-sm"}>{translate('accounts.detail.account-login')}: {data?.login}</span>
                            <span className={"text-sm"}>{translate('accounts.detail.account-status')}: {data?.status}</span>
                            <span
                                className={"text-sm"}>{translate('accounts.detail.account-last-update')}: {getDateByLocale(data?.dateUpdate, language)}</span>
                        </div>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-lg font-bold t"}>{translate('accounts.detail.installed-apps')}</span>
                        <Separator orientation={"horizontal"}/>
                        <div className={`grid ${data?.installed_apps?.length === 0 ? 'grid-cols-1' : 'lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-2 p-2'}`}>
                            {data?.installed_apps?.length === 0 ?
                                <span
                                    className={"w-full text-center font-bold p-2"}>{translate('accounts.detail.installed-apps-not-found')}</span> :
                                data?.installed_apps?.map((app, index) => (
                                        <ItemAppCard key={index} data={app}/>
                                    )
                                )}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}
