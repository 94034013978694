import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router";

type SidebarContextType = {
    isExpanded: boolean;
    toggleSidebar: () => void;
    pageName: string;
    setSelectPageName: (pageName: string) => void;
};

const SidebarContext = React.createContext<SidebarContextType | undefined>(undefined);

interface SidebarProviderProps {
    children: React.ReactNode;
}

const useSidebarState = (initialState: boolean = true): [any, (() => void), string, ((pageNameSelect: string) => void)] => {
    const location = useLocation();

    const [isExpanded, setIsExpanded] = useState(() => {
        // Получение значения из localStorage при первичной инициализации
        const savedState = localStorage.getItem("sidebarState");
        return savedState !== null ? JSON.parse(savedState) : initialState;
    });

    const [pageName, setPageName] = useState(location.pathname.split('/')[1]);

    useEffect(() => {
        localStorage.setItem("sidebarState", JSON.stringify(isExpanded));
    }, [isExpanded]);

    useEffect(() => {

    }, [pageName]);
    const selectPage = (pageNameSelect: string) => {
        setPageName(pageNameSelect);
    }
    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    return [isExpanded, toggleSidebar, pageName, selectPage];
};

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
    const [isExpanded, toggleSidebar, pageName, setSelectPageName] = useSidebarState();

    return (
        <SidebarContext.Provider value={{ isExpanded, toggleSidebar, pageName, setSelectPageName }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};


