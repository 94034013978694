import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {Input} from "../shared/Input";
import {useToast} from "../../contexts/ToastContext";
import {ErrorData, LocaleData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {AxiosError} from "axios";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../shared/select";

export const AddLocaleModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "locale-add";

    const {add} = useToast();
    const {translate} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [imgUrl, setImgUrl] = useState<string>('');

    const [error, setError] = useState<Record<string, { value: string }>>({});

    const [errorResponse, setErrorResponse] = useState<string>('');

    const [form, setForm] = useState<LocaleData>({
        id: 0,
        language: '',
        country: '',
        name: '',
        type: 'STANDARD',
    });

    const handlerAddLocale = () => {

        setError({});
        setErrorResponse('');
        setLoading(true);
        let hasError = false;

        if (form.language === '') {
            setError(prev => ({...prev, language: {value: 'locale.language_is_require'}}));
            hasError = true;
        }

        if (form.country === '') {
            setError(prev => ({...prev, country: {value: 'locale.country_is_require'}}));
            hasError = true;
        }

        if (form.name === '') {
            setError(prev => ({...prev, name: {value: 'locale.name_is_require'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.put('/locales/', {
            type: form.type,
            language: form.language.toLowerCase(),
            name: form.name,
            country: form.country.toLowerCase(),
        })
            .then((response) => {
                if (response.status === 200) {
                    setForm({
                        id: 0,
                        language: '',
                        country: '',
                        name: '',
                        type: 'STANDARD',
                    });

                    add({
                        type: "success",
                        message: "Locale added successfully",
                    });
                    setLoading(false);
                    data.success = true;
                    onClose();
                } else {
                    setErrorResponse(response.data.error_message);
                }
                setLoading(false);
            }).catch((error) => {
                const errorAxios = error as AxiosError<ErrorData>;
                if (errorAxios.response?.status === 400) {
                    setErrorResponse(error.response.data.error_message);
                } else
                    console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        setImgUrl(`https://purecatamphetamine.github.io/country-flag-icons/3x2/${form.country.toUpperCase()}.svg`);
    }, [form.country]);

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('locale.modal.title')}
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    <div className="flex flex-col gap-2 p-6 mr-24">
                        <Select
                            disabled={loading}
                            value={form.type}
                            onValueChange={(value) => setForm(prev => ({...prev, type: value}))}
                        >

                            <SelectTrigger
                                className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                <SelectValue placeholder="Country Select"/>
                            </SelectTrigger>

                            <SelectContent className={"flex flex-col"}>
                                <div className={"h-52 overflow-scroll pt-2"}>

                                    <SelectItem
                                        key={'STANDARD'}
                                        value={'STANDARD'}
                                        className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                    >
                                        <div className={"flex flex-row gap-2"}>
                                            <span>STANDARD</span>
                                        </div>
                                    </SelectItem>
                                    <SelectItem
                                        key={'TEST'}
                                        value={'TEST'}
                                        className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                    >
                                        <div className={"flex flex-row gap-2"}>
                                            <span>TEST</span>
                                        </div>
                                    </SelectItem>
                                </div>
                            </SelectContent>
                        </Select>

                        <span className="text-xs font-bold text-gray/50">{translate('locale.modal.country-locale-description')}</span>
                        <Input
                            type={"text"}
                            label={translate('locale.country')}
                            error={error.country?.value || ''}
                            disable={loading}
                            value={form.country}
                            onChange={(e) => setForm(prev => ({...prev, country: e.target.value}))}
                        />
                        <Input
                            type={"text"}
                            label={translate('locale.language')}
                            error={error.language?.value || ''}
                            value={form.language}
                            disable={loading}
                            onChange={(e) => setForm(prev => ({...prev, language: e.target.value}))}
                        />
                        <Input
                            type={"text"}
                            label={translate('locale.name')}
                            error={error.name?.value || ''}
                            value={form.name}
                            disable={loading}
                            onChange={(e) => setForm(prev => ({...prev, name: e.target.value}))}
                        />
                        <div className={"flex flex-row gap-2"}>
                            <span className={"text-xs font-bold text-gray"}>{translate('locale.modal.flag-country')}:</span>
                            <img
                                className={"h-4 w-6 bg-gray rounded-md"}
                                alt={"Flag country"}
                                src={imgUrl}
                            />
                        </div>
                        <span className="text-xs font-bold text-red">{errorResponse}</span>
                    </div>
                    <div/>
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handlerAddLocale} disabled={loading}>
                        {translate('def.add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
