import React, {useEffect, useState} from "react";
import {Card} from "../../components/shared/card";
import {Input} from "../../components/shared/Input";
import {SearchIcon} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";
import {SmsCountryData, SmsServiceData, transformJsonToSmsServiceList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useToast} from "../../contexts/ToastContext";
import {ItemSmsServiceCardLoader, SmsServiceItem} from "./sms-service-item";
import {Separator} from "../../components/shared/separator";
import {ItemSmsCountryItemLoader, SmsCountryItem} from "./sms-country-item";
import {Button} from "../../components/shared/button";
import {useModal} from "../../hooks/use-modal-store";

export const SmsServicesScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {add} = useToast();
    const {onOpen} = useModal();

    const [search, setSearch] = useState<string>('');
    const [loadingConfigs, setLoadingConfigs] = useState<boolean>(false);
    const [configs, setConfigs] = useState<SmsServiceData[]>([]);

    const onClickConfig = (service?: SmsServiceData) => {
        onOpen('sms-service-add', {smsServiceData: service});
    }

    const onClickCountry = (country?: SmsCountryData) => {
        onOpen('sms-country-add', {smsCountryData: country});
    }

    const openDeleteCountryModal = (id: string) => {
        onOpen(
            'confirm-modal',
            {
                confirmData: {
                    title: translate('def.delete') + '?',
                    description: 'Are you sure you want to delete this country?',
                    button_confirm: translate('def.delete'),
                    button_close: translate('def.close')
                }
            },
            {onConfirm: () => handlerDeleteCountry(id)}
        );
    }

    const handlerDeleteCountry = (id: string) => {
        axiosPrivate.delete(`/sms-services/country/delete?id=${id}`).then((response) => {
            if (response.status === 200) {
                add({type: 'info', message: 'Sms Country is deleted'});
                setConfigs(prevState => prevState.map(country => {
                    country.countries.filter(c => c.id.toString() !== id);
                    return country;
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handlerSwitchCountry = (is_active: boolean, id: string) => {
        axiosPrivate.patch(`/sms-services/country/switch?id=${id}&is_active=${is_active}`).then((response) => {
            if (response.status === 200) {
                add({type: 'info', message: 'Sms Country is updated'});
                setConfigs(prevState => prevState.map(country => {
                    country.countries.map(c => {
                        if (c.id.toString() === id) {
                            c.is_active = is_active;
                        }
                        return c;
                    })
                    return country;
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {

        setLoadingConfigs(true);

        axiosPrivate.get(`/sms-services/list?search=${search}`)
            .then((response) => {
                if (response.status === 200 && response.data.data) {

                    let smsServiceData = transformJsonToSmsServiceList(response.data.data);

                    if (search.length > 0) {

                        let filterSearch = smsServiceData.map((service) => {
                            return {
                                ...service,
                                countries: service.countries.filter((country) => {
                                    return country.locale.country.toLowerCase().includes(search.toLowerCase()) ||
                                        country.locale.language.toLowerCase().includes(search.toLowerCase()) ||
                                        country.country_code.toLowerCase().includes(search.toLowerCase()) ||
                                        (country.marker && country.marker.toLowerCase().includes(search.toLowerCase()));
                                })
                            };
                        });

                        setConfigs(filterSearch)
                    } else {
                        setConfigs(smsServiceData);
                    }
                } else {
                    setConfigs([]);
                }

                setLoadingConfigs(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingConfigs(false);
            });
    }, [search]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('sms-service.service.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between mb-2"}>
                    <div className={"flex flex-row gap-2"}>
                        <Button className={"w-36"} onClick={onClickConfig}>
                            <span className={"text-xs"}>{translate('sms-service.service.add-new-service')}</span>
                        </Button>

                        <Button className={"w-36 bg-orange/50 hover:bg-orange"} onClick={onClickCountry}>
                            <span className={"text-xs"}>{translate('sms-service.service.add-new-country')}</span>
                        </Button>
                    </div>
                    <div className={"flex w-52"}>
                        <Input
                            type={"text"}
                            label={translate('def.search')}
                            onChange={(e) => setSearch(e.target.value)}
                            icon={<SearchIcon size={20}/>}
                        />
                    </div>
                </div>

                {loadingConfigs && (
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>
                            <div className={"flex flex-row gap-4 items-center"}>
                                <ItemSmsServiceCardLoader/>
                            </div>

                            <Separator orientation={"horizontal"}/>
                            <div
                                className={`grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                <ItemSmsCountryItemLoader/>
                                <ItemSmsCountryItemLoader/>
                                <ItemSmsCountryItemLoader/>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>

                            <div className={"flex flex-row gap-4 items-center"}>
                                <ItemSmsServiceCardLoader/>
                            </div>

                            <Separator orientation={"horizontal"}/>

                            <div
                                className={`grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                <ItemSmsCountryItemLoader/>
                                <ItemSmsCountryItemLoader/>
                                <ItemSmsCountryItemLoader/>
                            </div>
                        </div>
                    </div>
                )}

                {!loadingConfigs && (
                    <div className={"flex flex-col gap-2"}>
                        {configs.length === 0 ? (
                            <span
                                className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('sms-service.service.no_found_sms_services')}</span>
                        ) : (
                            configs.map((config, index) => (
                                    <div
                                        className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>
                                        <div className={"flex flex-row gap-4 items-center"}>
                                            <SmsServiceItem key={index} data={config} onClick={onClickConfig}/>
                                        </div>
                                        <Separator orientation={"horizontal"}/>
                                        {config.countries.length === 0 ? (
                                            <span
                                                className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('sms-service.service.no_found_sms_countries')}</span>
                                        ) : (
                                            <div
                                                className={`grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                                {config.countries.map((country, index) => (

                                                    <SmsCountryItem key={index} data={country} onEdit={onClickCountry} onDelete={openDeleteCountryModal}
                                                                    onSwitch={handlerSwitchCountry}/>

                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )
                            )
                        )}
                    </div>
                )}
            </Card>
        </div>
    )
}
