import {useLanguage} from "../../contexts/LanguageContext";

const NotFound = () => {

    const {translate} = useLanguage()

    return (
        <div className="flex flex-col w-full items-center justify-items-center gap-4">
            <img src="/images/404.gif" alt="404" className="h-60"/>
            <span className="text-4xl text-gray dark:text-white">{translate('error.page-not-found')}</span>
        </div>
    )
}

export default NotFound