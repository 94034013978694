import React from "react";
import {ServerData} from "../../../lib/transform-json";

type PhonesServerType = {
    serverData: ServerData;
    setForm: (form: ServerData) => void;
    loading: boolean;
    error: Record<string, { value: string | undefined }>;
}

export const PhonesServer: React.FC<PhonesServerType> = ({serverData, setForm, loading, error}) => {



    return (
        <div>PhonesServer</div>
    );
}
