import TopBar from "../navigation/TopBar";
import Auth from "../auth/Auth";

export default function AuthLayout() {
    return (
        <div className="flex flex-col">
            <div className="absolute w-screen top-0">
                <TopBar/>
            </div>
            <Auth/>
        </div>
    );
}