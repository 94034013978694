import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {Input} from "../shared/Input";
import {Avatar, AvatarFallback, AvatarImage} from "../shared/avatar";
import {Upload} from "lucide-react";
import {Separator} from "../shared/separator";
import {transformJsonToUserData, UserData} from "../../lib/transform-json";
import {useAuth} from "../../contexts/AuthContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useToast} from "../../contexts/ToastContext";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";

type MenuType = "MAIN" | "PASSWORD" | "DEVICES";

export const UserProfileModal = () => {
    const {isOpen, onClose, type} = useModal();

    const isModalOpen = isOpen && type === "user-profile";

    const auth = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const {add} = useToast();
    const navigate = useNavigate();

    const {translate} = useLanguage();

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<UserData | null>(auth.user);
    const [devices, setDevices] = useState<any[]>([]);

    const [menu, setMenu] = useState<MenuType>("MAIN");

    const [error, setError] = useState<Record<string, { value: string }>>({});

    const loadUser = () => {
        axiosPrivate.get('/users/current')
            .then((res) => {
                if (res.status === 200) {
                    setData(transformJsonToUserData(res.data.data));
                    loadDevices();
                } else {
                    add({
                        type: "error",
                        message: "Error while loading user"
                    })
                    onClose();
                }
            }).catch((err) => {
            add({
                type: "error",
                message: "Error while loading user"
            })
            onClose();
        });
    }

    const loadDevices = () => {
        const responseDevices = axiosPrivate.get('/auth/devices');

        responseDevices.then((res) => {
            if (res.status === 200) {
                setDevices(res.data.data);
            } else {
                add({
                    type: "error",
                    message: "Error while loading devices"
                })
                onClose();
            }
        }).catch((err) => {
            add({
                type: "error",
                message: "Error while loading devices"
            })
            onClose();
        });
    }

    const handlerPickUpAvatar = () => {
        const input = document.getElementById("fileInput");
        input?.click();
    }

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('avatar', file);
            axiosPrivate.post(
                '/users/update/avatar',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        auth.setUser(transformJsonToUserData(res.data.data));
                        setData(transformJsonToUserData(res.data.data));
                    }
                }).catch((err) => {
                console.log(err);
            });
        }
    };

    const handelLogout = (item: any) => {
        const responseDevices = axiosPrivate.delete('/auth/logout?token=' + item.token);

        responseDevices.then((res) => {
            if (item.current) {
                auth.setUser(null);
                navigate('/auth');
            }

            if (res.status === 200) {
                loadDevices()
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        if (isModalOpen) {
            loadUser();
        }
    }, [isModalOpen]);

    return (
        <Dialog open={isModalOpen}>
            <DialogContent className="bg-white text-black p-2 min-w-[700px] h-[400px] items-start">
                <DialogHeader className="pt-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('profile.title')}
                    </DialogTitle>
                </DialogHeader>
                <div className="flex flex-col p-2 gap-2">
                    {/* Menu Section */}
                    <div className="flex flex-row gap-2 overflow-scroll">
                        <span
                            onClick={() => setMenu('MAIN')}
                            className={`transition-all cursor-pointer text-sm text-center p-1 rounded-md bg-green/30 hover:bg-green/70 font-bold text-gray-dark dark:text-white ${menu === 'MAIN' ? 'bg-green/70' : ''}`}>
                            {translate('profile.main-info')}
                        </span>
                        <span
                            onClick={() => setMenu('PASSWORD')}
                            className={`transition-all cursor-pointer text-sm text-center p-1 rounded-md bg-green/30 hover:bg-green/70 font-bold text-gray-dark dark:text-white ${menu === 'PASSWORD' ? 'bg-green/70' : ''}`}>
                              {translate('profile.password')}
                        </span>
                        <span
                            onClick={() => setMenu('DEVICES')}
                            className={`transition-all cursor-pointer text-sm text-center p-1 rounded-md bg-green/30 hover:bg-green/70 font-bold text-gray-dark dark:text-white ${menu === 'DEVICES' ? 'bg-green/70' : ''}`}>
                              {translate('profile.devices')}
                        </span>
                    </div>
                    <Separator/>
                    {/* Content Section */}
                    <div className="flex flex-col h-[180px] overflow-scroll border border-gray p-3 rounded-md">
                        {menu === "MAIN" && (
                            <>
                                <div className={"flex flex-col gap-2"}>
                                    <div className={"flex flex-row gap-2"}>
                                        <div className={"relative flex group cursor-pointer items-center justify-center h-24 w-24"}
                                             onClick={handlerPickUpAvatar}>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                accept=".gif,.jpg,.jpeg,.png"
                                                style={{display: 'none'}}
                                                onChange={handleImageChange}
                                            />
                                            <Avatar className={`h-24 w-24 transition-all`}>
                                                <AvatarFallback delayMs={100}>
                                                    {`${data?.name.charAt(0)}${data?.surname.charAt(0)}`}
                                                </AvatarFallback>
                                                <AvatarImage src={data?.imageSrc}/>
                                            </Avatar>
                                            <Upload size={30}
                                                    className={"absolute text-center text-transparent group-hover:text-white/50 transition-all"}/>
                                        </div>
                                        <div className={"flex flex-col gap-3 items-center justify-center"}>
                                            <Input type={"text"} label={translate('profile.name')} value={data?.name}/>
                                            <Input type={"text"} label={translate('profile.surname')} value={data?.surname}/>
                                        </div>
                                    </div>
                                    <Input type={"email"} label={translate('auth.email')} value={data?.email}/>
                                </div>
                            </>
                        )}
                        {menu === "PASSWORD" && (
                            <>
                                <div className={"flex flex-col gap-2"}>
                                    <Input type={"password"} label={translate('profile.password')}/>
                                    <Input type={"password"} label={translate('profile.password-confirm')}/>
                                    <div className={"flex justify-end"}>
                                        <Button>
                                            Change
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {menu === "DEVICES" && (
                            <>
                                <div className={"flex flex-col gap-2"}>
                                    {devices.map((item, index) => (
                                        <div key={index} className={"flex flex-row justify-between items-center gap-2 overflow-scroll"}>
                                            <span
                                                title={item.device}
                                                className={`text-xs font-bold whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[500px] truncate ${item.current ? 'text-green' : 'text-gray-dark dark:text-white'}`}>{item.device}
                                            </span>
                                            <Button
                                                className={"bg-orange/30 hover:bg-orange/50 h-8 p-1.5"}
                                                onClick={() => handelLogout(item)}>
                                                <span className={"text-xs"}>{translate('auth.logout')}</span>
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <DialogFooter className="flex flex-row p-4 justify-end items-end">
                    <div>
                        <Button className={"bg-orange/30 hover:bg-orange/50"} onClick={handleClose}>
                            {translate('def.cancel')}
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}