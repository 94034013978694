import React, {useEffect, useState} from "react";
import {Loader} from "./loader";
import {cn} from "../../lib/utils";

type TabsProps = {
    tabs: string[];
    children?: React.ReactNode;
    classNameDivChildren?: string;
    onTabChange?: (tab: string) => void;
}

const TabsContainer: React.FC<TabsProps> = ({tabs, classNameDivChildren, children, onTabChange}) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const handlerTabChange = (tab: string) => {
        setActiveTab(tab);
        onTabChange && onTabChange(tab);
    }

    return (
        <div className={"flex flex-col w-full"}>

            <div className={"flex flex-row pl-2"}>
                {tabs.map(tab => (
                    <div
                        key={tab}
                        className={`cursor-pointer justify-start font-bold min-w-[70px] text-center rounded-t-md overflow-hidden ${activeTab === tab ? "bg-green/60" : "bg-green/10"} transition-all`}
                        onClick={() => handlerTabChange(tab)}
                    >
                        <span className={"min-w-20 text-gray-dark dark:text-white p-2 capitalize"}>{tab.toLowerCase()}</span>
                    </div>
                ))}
            </div>

            <div
                className={cn("border border-gray-border dark:border-gray-dark-border rounded-md", classNameDivChildren)}>
                {children}
            </div>
        </div>
    );
};

export {TabsContainer};