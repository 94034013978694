import React, {
    createContext, RefObject,
    useContext,
    useState,
} from "react";
import {cn} from "../lib/utils";
import {ToastPositionType, ToastProps, Toast, positionClasses} from "../components/shared/toast";

const ToastContext = createContext<ToastContextType | undefined>(undefined);

type ToastProviderProps = {
    children: React.ReactNode;
}

type ToastContextType = {
    add: (toast: Omit<ToastProps, "id">) => void;
    remove: (toastId: number, ref: RefObject<HTMLDivElement>) => void;
    position: ToastPositionType;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);
    const [position, setPosition] = useState<ToastPositionType>("topCenter");

    const add = (toast: Omit<ToastProps, "id">) => {
        if (toast.position && toast.position !== position) {
            setPosition(toast.position);
        }

        setToasts((toasts) => [...toasts, {...toast, id: Math.random() * 10000}]);
    };

    const remove = (toastId: number, ref: React.RefObject<HTMLDivElement>) => {
        ref?.current?.classList.add("animate-toast-out");

        ref?.current?.addEventListener("animationend", () => {
            // lets remove it
            setToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
        });
    };

    return (
        <div className="">
            <ToastContext.Provider value={{add, remove, position}}>
                {children}
                {/* toast list */}
                <div
                    className={cn(
                        positionClasses[position],
                        "fixed w-screen max-w-lg z-50"
                    )}>
                    {toasts.map((toast) => (
                        <Toast key={toast.id} {...toast} />
                    ))}
                </div>
            </ToastContext.Provider>
        </div>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
