import React, {useEffect} from "react";
import {Check} from "lucide-react";
import {cn} from "../../lib/utils";

type CheckboxProps = {
    label?: string;
    defaultChecked?: boolean;
    onCheck?: (checked: boolean) => void;
    disable?: boolean
    className?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({label, defaultChecked = false, disable = false, onCheck, className}) => {

    const [checked, setChecked] = React.useState(defaultChecked);

    const handleCheckboxChange = () => {
        if (!disable) {
            setChecked(!checked);
            onCheck && onCheck(!checked);
        }
    };

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <div className={`${disable ? '' : 'cursor-pointer'} inline-flex items-center`} onClick={handleCheckboxChange}>
            <div className={cn("flex h-5 w-5 rounded-md border-2 border-green/80 justify-center items-center transition-all",
            checked ? 'bg-green/80' : 'bg-main dark:bg-main-dark', className)}>
                <Check className={`${checked ? 'text-white' : 'text-transparent'} transition-all`}/>
            </div>
            {label && <span className="ml-2 text-xs text-gray-dark dark:text-white font-bold">{label}</span>}
        </div>
    );
}