import React, {ChangeEvent, KeyboardEvent, useEffect, useRef, useState, ClipboardEvent} from 'react';
import {X} from "lucide-react";
import {cn} from "../../lib/utils";
import {v4 as uuid} from 'uuid'

type Chip = {
    id: string;
    text: string;
};

type InputChipsProps = {
    items: string[];
    importItems?: string[];
    onChange?: (keywords: string[]) => void;
    maxItems?: number;
    placeholder?: string;
    className?: string;
    duplicate?: boolean;
};

const InputChips: React.FC<InputChipsProps> = ({
                                                   items,
                                                   importItems,
                                                   onChange,
                                                   placeholder = 'Add an item...',
                                                   className,
                                                   maxItems = 500,
                                                   duplicate = false
                                               }) => {

    const [chips, setChips] = useState<Chip[]>(items.filter((item) => item.trim() !== '').slice(0, maxItems).map(item => ({id: item, text: item})));
    const [inputValue, setInputValue] = useState('');
    const [removing, setRemoving] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && inputValue.trim()) {
            addChips(inputValue);
            setInputValue('');
            e.preventDefault();
        }
        if (e.key === 'Backspace' && !inputValue) {
            setChips(prevChips => prevChips.slice(0, prevChips.length - 1));
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        const pastedText = e.clipboardData.getData('text');
        addChips(pastedText, true);
        setInputValue('');
        e.preventDefault();
    };

    const addChips = (text: string, comaSplit: boolean = false) => {

        const delimiter = comaSplit ? /[\n,]/ : /\n/;

        const newChips = text.trim().split(delimiter).filter((item) => item.trim() !== '')
            .map(text => ({ id: uuid(), text }));

        // Добавляем новые чипы, если не превышаем максимальное количество
        setChips(prevChips => {
            let updatedChips = [...prevChips, ...newChips];

            if (!duplicate) {
                const seen = new Set<string>();
                const duplicateIds: string[] = [];

                updatedChips.forEach(chip => {
                    if (seen.has(chip.text)) {
                        duplicateIds.push(chip.id);
                        setRemoving(prevRemoving => [...prevRemoving, chip.id]);
                    } else {
                        seen.add(chip.text);
                    }
                });

                if (duplicateIds.length > 0) {
                    setTimeout(() => {
                        setChips(prevChips => prevChips.filter(chip => !duplicateIds.includes(chip.id)));
                        setRemoving(prevRemoving => prevRemoving.filter(id => !duplicateIds.includes(id)));
                    }, 800);
                }
            }

            return updatedChips;
        });
    };

    const handleRemoveKeyword = (id: string) => {
        setChips(prevChips => prevChips.filter(chip => chip.id !== id));
    };

    useEffect(() => {
        onChange && onChange(chips.map(chip => chip.text));
    }, [chips]);

    useEffect(() => {
        if (!importItems || importItems.length <= 0) return;

        setChips(importItems.filter((item) => item.trim() !== '').slice(0, maxItems).map(item => ({id: item, text: item})));
    }, [importItems]);

    const handleDivClick = () => {
        inputRef.current?.focus();
    };

    return (
        <div className={cn("border border-gray/30 bg-white/10 rounded-md p-1 cursor-text h-44 max-h-44 overflow-scroll", className)} onClick={handleDivClick}>
            <div className="flex flex-wrap gap-1 items-center">
                {chips.map((chip, index) => (
                    <Tag key={chip.id} index={index} tag={chip.text} onRemove={() => handleRemoveKeyword(chip.id)}
                         className={removing.includes(chip.id) ? "bg-red/40" : ""}/>
                ))}
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    placeholder={placeholder}
                    className="min-w-0 bg-transparent flex-grow border-none outline-none p-1 text-gray-dark dark:text-white text-sm"
                    ref={inputRef}
                />
            </div>
        </div>
    );
};

type TagInputProps = {
    className?: string;
    index: number;
    tag: string;
    onRemove: () => void;
};

const Tag: React.FC<TagInputProps> = ({tag, onRemove, className}) => {
    return (
        <div
            onClick={onRemove}
            className={cn("flex bg-gray-200 rounded-md p-1 transition-all text-gray-dark dark:text-white text-sm bg-blue/40 hover:bg-blue/80 cursor-pointer items-center animate-scale-in", className)}>
            <span className={"pr-1"}>{tag}</span>
            <X
                size={14}
                className="text-red/70 cursor-pointer transition-all"
            />
        </div>
    );
}

export default InputChips;
