import React, {createContext, useCallback, useContext, useEffect, useRef} from "react";
import {Client, IFrame} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {BASE_URL} from "../config/axios";
import {useRefreshToken} from "../hooks/use-axios-private";
import {useAuth} from "./AuthContext";

interface WebSocketContextType {
    createConnection: (key: string) => void;
    getConnection: (key: string) => Client | void;
    getConnectionStatus: (key: string) => boolean;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
                                                                               children,
                                                                           }) => {

    const {user} = useAuth();

    const connections = useRef<{ [key: string]: Client }>({});
    const connectionStatus = useRef<{ [key: string]: boolean }>({});
    const refreshToken = useRefreshToken();

    const createConnection = useCallback((key: string) => {
        if (connections.current[key]) {
            connections.current[key].deactivate();
        }

        if (user) {
            console.log(`Creating websocket connection to key: ${key}`);

            const sock = new SockJS(BASE_URL + "/websocket");
            const client = new Client({
                webSocketFactory: () => sock,
                onConnect: (frame: IFrame) => {
                    console.log(`Connected websocket to key: ${key}`);
                    connectionStatus.current[key] = true;
                },
                onStompError: (frame: IFrame) => {
                    console.error(`Broker reported error: ${frame.headers['message']}`);
                    console.error(`Additional details: ${frame.body}`);
                },
                onDisconnect: (frame: IFrame) => {
                    console.log(`Disconnected websocket from key: ${key}`);
                    connectionStatus.current[key] = false;
                },
                onWebSocketClose: (event: CloseEvent) => {
                    console.log(`Websocket closed from key: ${key}`);
                    connectionStatus.current[key] = false;
                    // Автоматическое переподключение
                    setTimeout(() => {
                        if (!connectionStatus.current[key]) {
                            console.log(`Attempting to reconnect websocket for key: ${key}`);
                            createConnection(key); // Попытка переподключения
                        }
                    }, 5000); // Переподключение через 5 секунд
                },
                reconnectDelay: 5000,
            });

            sock.onclose = async (event: CloseEvent) => {
                if (event.code === 401 || event.code === 403) {
                    const refreshed = await refreshToken();
                    if (refreshed && !connectionStatus.current[key]) {
                        client.activate();
                    }
                }
            };

            connections.current[key] = client;
            client.activate();
        }
    }, [refreshToken, user]);

    useEffect(() => {
        return () => {
            Object.values(connections.current).forEach((client) => client.deactivate());
        };
    }, []);

    const getConnection = useCallback((key: string) => {
        return connections.current[key] && connections.current[key].active ? connections.current[key] : createConnection(key);
    }, [createConnection]);

    const getConnectionStatus = useCallback((key: string) => {
        return connectionStatus.current[key];
    }, []);

    return (
        <WebSocketContext.Provider value={{createConnection, getConnection, getConnectionStatus}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return context;
};
