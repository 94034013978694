import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {CampaignData, transformJsonToCampaignDataList, UserData} from "../../lib/transform-json";
import {Card} from "../../components/shared/card";
import {Input} from "../../components/shared/Input";
import {SearchIcon} from "lucide-react";
import {CampaignItem, ItemCampaignCardLoader} from "./campaign-item";
import {Separator} from "../../components/shared/separator";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";

export const CampaignsUsersScene: React.FC = () => {

    const {translate} = useLanguage();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
    const [users, setUsers] = useState<UserData[]>([]);

    const onClick = (id: string) => {
        navigate(`/campaign/detail?cuid=${id}`);
    }

    useEffect(() => {

        setLoading(true);

        axiosPrivate.get(`/campaigns/list/all?search=${search}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                let campaignData = transformJsonToCampaignDataList(response.data.data);
                setCampaigns(campaignData);

                let users = campaignData.map((campaign) => campaign.user);

                let uniqueUsers = Array.from(new Set(users.map(user => user?.uuid)))
                    .map(id => users.find(user => user?.uuid === id));

                setUsers(uniqueUsers.map((user) => user as UserData));
            }

            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }, [search]);

    return (
        <div className="flex flex-col gap-4">
            <div className={"flex flex-row justify-between"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('campaigns.list-all.title-page')}</span>
                <div className={"flex w-52"}>
                    <Input
                        type={"text"}
                        label={translate('def.search')}
                        onChange={(e) => setSearch(e.target.value)}
                        icon={<SearchIcon size={20}/>}
                    />
                </div>
            </div>

            <Card>
                {loading && (
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>
                            <div className={"flex flex-row gap-4 items-center"}>
                                <div className={"h-10 w-10 bg-gray/50 rounded-md animate-[pulse_1.5s_infinite]"}></div>
                                <div className={"h-6 w-52 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                            </div>

                            <Separator orientation={"horizontal"}/>
                            <div
                                className={`grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                <ItemCampaignCardLoader/>
                                <ItemCampaignCardLoader/>
                                <ItemCampaignCardLoader/>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>

                            <div className={"flex flex-row gap-4 items-center"}>
                                <div className={"h-10 w-10 bg-gray/50 rounded-md animate-[pulse_1.5s_infinite]"}></div>
                                <div className={"h-6 w-52 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                            </div>

                            <Separator orientation={"horizontal"}/>

                            <div
                                className={`grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                <ItemCampaignCardLoader/>
                                <ItemCampaignCardLoader/>
                                <ItemCampaignCardLoader/>
                            </div>
                        </div>
                    </div>
                )}

                {!loading && users.length > 0 && (
                    <div className={"flex flex-col gap-2"}>
                        {users.map((user, index) => (
                            <div key={index}
                                 className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4"}>

                                <div className={"flex flex-row gap-4 items-center"}>
                                    <Avatar className={"h-10 w-10 transition-all"}>
                                        <AvatarFallback delayMs={500}>
                                            {user?.name?.charAt(0).toUpperCase()}{user?.surname?.charAt(0).toUpperCase()}
                                        </AvatarFallback>
                                        <AvatarImage
                                            src={user?.imageSrc}
                                        />
                                    </Avatar>
                                    <span className={"font-bold text-gray-dark dark:text-white"}>{user.username} ({user.name} {user.surname})</span>
                                </div>

                                <Separator orientation={"horizontal"}/>

                                <div
                                    className={`grid ${campaigns.length === 0 && !loading ? 'grid-cols-1' : 'lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                                    {campaigns.filter((campaign) => campaign.user?.uuid === user.uuid).map((campaign, index) => (
                                        <CampaignItem key={"campaign_" + index} campaignData={campaign} onClick={onClick}/>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {!loading && users.length === 0 && (
                    <span
                        className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('campaigns.my.no-found-campaigns')}</span>
                )}
            </Card>
        </div>
    );
}
