import React from "react";
import {useAuth} from "../../contexts/AuthContext";
import AccessDenied from "../../scenes/error/AccessDenied";

interface RequireRoleProps {
    children: React.ReactNode;
    allowedRoles: string[];
    isShowDeniedPage?: boolean;
}

const RequireRole: React.FC<RequireRoleProps> = ({children, allowedRoles, isShowDeniedPage = false}) => {
    const auth = useAuth();
    const isAllowed = auth?.user?.roles?.some(role => allowedRoles.includes(role)) && auth?.user?.status === 'ACTIVE';
    if (isAllowed) {
        return <>{children}</>;
    } else {
        return isShowDeniedPage ? <AccessDenied/> : null;
    }
}

export default RequireRole;