import React from "react";
import {ProxyConfigData} from "../../lib/transform-json";
import {useLanguage} from "../../contexts/LanguageContext";
import {ActionTooltip} from "../../components/shared/tooltip";

type ProxyConfigItemProps = {
    proxyConfig: ProxyConfigData;
    onClick?: (id: number) => void;
}

const ProxyConfigItem: React.FC<ProxyConfigItemProps> = ({proxyConfig, onClick}) => {

    const {translate} = useLanguage();

    return (
        <div
            onClick={() => onClick && onClick(proxyConfig.id || 0)}
            className="flex flex-col cursor-pointer rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border">
            <div
                className={`flex flex-row w-full bg-gray/10 justify-between items-center p-2`}>

                <div className={"flex flex-row gap-2 items-center"}>
                            <span title={proxyConfig.service_type}
                                  className={"max-w-[150px] whitespace-nowrap truncate font-bold text-gray-dark dark:text-white"}>{proxyConfig.service_type}</span>

                    {proxyConfig.locale && (
                        <ActionTooltip
                            label={"Country for app: " + proxyConfig?.locale.country + "-" + proxyConfig?.locale.language + (proxyConfig?.locale.type === 'TEST' ? ' (TEST)' : '')}>
                            <img
                                className={`h-4 w-4 ${proxyConfig?.locale.type === 'TEST' ? 'grayscale' : ''}`}
                                alt={proxyConfig?.locale.country.toUpperCase()}
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${proxyConfig?.locale.country.toUpperCase()}.svg`}/>
                        </ActionTooltip>
                    )}
                </div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>{translate('proxy_type')}:</span>
                        <span
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1"}>{proxyConfig.proxy_type}</span>
                    </div>
                    <div className={"flex flex-row items-start"}>
                        <span className={"text-gray/60 text-xs"}>{translate('auth_param')}:</span>
                        <span
                            className={"text-gray-dark dark:text-white text-xs font-bold ml-1"}>Key - {proxyConfig.auth_params?.auth_key} Value: {proxyConfig.auth_params?.auth_value}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ItemProxyCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
            </div>
        </div>
    )
}

export {ProxyConfigItem, ItemProxyCardLoader}
