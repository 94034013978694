import {useEffect, useState} from 'react';
import useAxiosPrivate from "../../hooks/use-axios-private";

export type AppSearchData = {
    name?: string;
    publisher?: string;
    image?: string;
    loading?: boolean;
}

const useAppSearch = (appIdOrUrl: string, defaultLocale: string) => {

    const axiosPrivate = useAxiosPrivate();

    const [appInfo, setAppInfo] = useState<AppSearchData | null>(null);
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!appIdOrUrl || !defaultLocale) {
            setAppInfo(null);
            return;
        }

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setAppInfo(
            {loading: true}
        );

        const timeout = setTimeout(async () => {
            try {
                const response = await axiosPrivate.get(`/apps/search?search=${appIdOrUrl}&defaultLocale=${defaultLocale}`);

                const {name, publisher, image} = response.data.data;

                setAppInfo(
                    {name, publisher, image, loading: false}
                );
            } catch (error) {
                console.error('Error fetching app info:', error);
                setAppInfo(null);
            }
        }, 1000);

        setDebounceTimeout(timeout);

        return () => clearTimeout(timeout);
    }, [appIdOrUrl, defaultLocale]);

    return appInfo;
};

export default useAppSearch;
