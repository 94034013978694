import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router";
import {MachineData, transformJsonToMachineData} from "../../lib/transform-json";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useNavigate} from "react-router-dom";
import {Card} from "../../components/shared/card";
import {Separator} from "../../components/shared/separator";

export const MachineDetailScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const nameParam = searchParams.get('name') || '';

    const [machine, setMachine] = React.useState<MachineData | null>(null);

    const loadMachine = () => {
        axiosPrivate.get(`/machines/?name=${nameParam}`).then((response) => {
            // setLoading(false);
            if (response.status === 200 && response.data.data) {
                console.log(response.data.data);
                setMachine(transformJsonToMachineData(response.data.data));
            } else {
                navigate('/not-found');
            }
        }).catch((error) => {
            navigate('/not-found');
            // setLoading(false);
        });
    }

    const hasLoaded = useRef(false);

    useEffect(() => {
        if (!hasLoaded.current) {
            hasLoaded.current = true;
            loadMachine();
        }
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('machines.detail.title-page')}</span>

            <Card>
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex flex-row gap-2"}>
                        <AvatarMachine version={machine?.versionAndroid || "0"}/>
                        <div className={"flex flex-col items-start justify-center"}>
                            <div className={"flex flex-row gap-2 items-center justify-center"}>
                                <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">{translate('machines.detail.name')}:</span>
                                <span className="text-lg font-bold text-gray-dark dark:text-white">{machine?.name}</span>
                            </div>
                            <div className={"flex flex-row gap-2 items-center justify-center"}>
                                <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">{translate('machines.detail.type-android')}:</span>
                                <span className="text-lg font-bold text-gray-dark dark:text-white">{machine?.androidVersion}</span>
                            </div>
                        </div>
                    </div>
                    <span className="text-lg font-bold text-gray-dark dark:text-white">{translate('machines.detail.info')}</span>
                    <div className={"flex flex-col gap-1 rounded-md border border-gray p-2"}>
                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">{translate('machines.detail.account')}:</span>
                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine?.account}</span>
                        </div>
                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">{translate('machines.detail.status')}:</span>
                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine?.status}</span>
                        </div>
                        {machine?.params && (
                            <div className="flex flex-col gap-3">
                                <Separator orientation={"horizontal"}/>
                                <span className="text-lg font-bold text-gray-dark/80 dark:text-white/80">{translate('machines.detail.params')}:</span>
                                <div className={"flex flex-col gap-1"}>
                                    {machine.params.serial && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Serial:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.serial}</span>
                                        </div>
                                    )}
                                    {machine.params.imei && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Imei:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.imei}</span>
                                        </div>
                                    )}
                                    {machine.params.build && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Build:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.build}</span>
                                        </div>
                                    )}
                                    {machine.params.rpiPath && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Rpi Path:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.rpiPath}</span>
                                        </div>
                                    )}
                                    {machine.params.rpiId && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Rpi ID:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.rpiId}</span>
                                        </div>
                                    )}
                                    {machine.params.tcpIpPortAdb && (
                                        <div className={"flex flex-row gap-2 items-center justify-start"}>
                                            <span className="text-xs font-bold text-gray-dark/80 dark:text-white/80">Tcp IP:PORT:</span>
                                            <span className="text-lg font-bold text-gray-dark dark:text-white">{machine.params.tcpIpPortAdb}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
}

const AvatarMachine: React.FC<{ version: string }> = ({version}) => {

    const getBgColor = (version: string) => {
        switch (version) {
            case '9':
                return 'bg-gradient-to-r from-green to-blue';
            case '10':
                return 'bg-gradient-to-r from-purple via-pink to-red';
            case '11':
                return 'bg-gradient-to-r from-yellow to-orange';
            case '12':
                return 'bg-gradient-to-r from-teal to-cyan';
            case '13':
                return 'bg-gradient-to-r from-indigo to-purple';
            case '14':
                return 'bg-gradient-to-r from-red to-pink';
            default:
                return 'bg-gray';
        }
    };

    return (
        <div className={`flex flex-col w-16 h-16 items-center justify-center rounded-md ${getBgColor(version)}`}>
            <span className="text-xl font-bold text-gray-dark dark:text-white">{version}</span>
        </div>
    );
}
