import React, {createContext, useContext, useEffect, useState} from 'react';
import {ApiKeyData, UserData} from "../lib/transform-json";

interface AuthProviderProps {
    children: React.ReactNode;
}

interface AuthContextType {
    user: UserData | null;
    setUser: (user: UserData | null) => void;
    apiKey: ApiKeyData | null;
    setApiKey: (apiKey: ApiKeyData | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {

    const value = localStorage.getItem('user');
    const apiKeyValue = localStorage.getItem('apiKey');

    const userObject = value ? JSON.parse(value) : null;
    const apiKeyObject = apiKeyValue ? JSON.parse(apiKeyValue) : null

    const [user, setUser] = useState<UserData | null>(userObject);
    const [apiKey, setApiKey] = useState<ApiKeyData | null>(apiKeyObject);

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        localStorage.setItem('apiKey', JSON.stringify(apiKey));
    }, [apiKey]);

    return (
        <AuthContext.Provider value={{user, setUser, apiKey, setApiKey}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};