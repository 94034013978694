import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import React, {useEffect} from "react";
import {Input} from "../../components/shared/Input";
import {PlusCircle, SearchIcon} from "lucide-react";
import {ItemProxyCardLoader, ProxyConfigItem} from "./proxy-config-item";
import {ProxyConfigData, transformJsonToProxyConfigDataList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Button} from "../../components/shared/button";
import {Separator} from "../../components/shared/separator";
import {useModal} from "../../hooks/use-modal-store";

export const ProxyConfigsScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {isOpen, onOpen, data} = useModal();

    const [search, setSearch] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const [configs, setConfigs] = React.useState<ProxyConfigData[]>([]);

    const onClick = (id: number) => {
        console.log(id);
    }

    const handlerAddConfig = () => {
        onOpen('add-proxy-config');
    }

    const loadConfigs = () => {
        setLoading(true);

        axiosPrivate.get(`/proxies/configs`).then((response) => {
            if (response.status === 200) {
                setConfigs(transformJsonToProxyConfigDataList(response.data.data).filter((config) => {
                    return search.length === 0 || config.service_type.toUpperCase().includes(search.toUpperCase()) ||
                        (config.proxy_type && config.proxy_type.toUpperCase().includes(search.toUpperCase())) ||
                        config.locale?.country.toUpperCase().includes(search.toUpperCase())
                }));
            }

            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadConfigs();
    }, [axiosPrivate, search]);

    useEffect(() => {
        if (!isOpen && data.success) {
            loadConfigs();
        }
    }, [isOpen, data]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('proxy.configs.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between mb-2"}>
                    <Button onClick={handlerAddConfig}>
                        <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                            <PlusCircle size={18}/>
                            <span className={"text-xs"}>{translate('proxy.configs.add-new-config')}</span>
                        </div>
                    </Button>
                    <div className={"flex w-52"}>
                        <Input
                            type={"text"}
                            label={"Search"}
                            onChange={(e) => setSearch(e.target.value)}
                            icon={<SearchIcon size={20}/>}
                        />
                    </div>
                </div>
                <Separator orientation={"horizontal"}/>
                <div
                    className={`grid ${configs.length === 0 && !loading ? 'grid-cols-1' : 'lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                    {loading ? <ItemProxyCardLoader/> : configs.length === 0 ?
                        <span
                            className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('proxy.configs.no_found_configs')}</span> :
                        configs.map((config, index) => (
                                <ProxyConfigItem key={index} proxyConfig={config} onClick={onClick}/>
                            )
                        )}
                </div>
            </Card>
        </div>
    )
}
