import React from "react";
import TopBar from "../navigation/TopBar";
import {ServerUnavailable} from "../../scenes/error/ServerUnavailable";

export const ServerUnavailableLayout: React.FC = () => {
    return (
        <div className="flex flex-col">
            <div className="absolute w-screen top-0">
                <TopBar/>
            </div>
            <ServerUnavailable/>
        </div>
    )
}