import {Card} from "../../components/shared/card";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {ActionTooltip} from "../../components/shared/tooltip";
import {MachineDataList, transformJsonToMachineDataList, WorkingTask} from "../../lib/transform-json";
import {getDateByLocale} from "../../lib/utils";
import {useLanguage} from "../../contexts/LanguageContext";
import {ChevronRight} from "lucide-react";
import {useModal} from "../../hooks/use-modal-store";

export const MachineTasksPart: React.FC = () => {

    const axiosPrivate = useAxiosPrivate();
    const {language, translate} = useLanguage();

    const {onOpen} = useModal();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: 'timeUpdate', ask: false});
    const [search, setSearch] = useState<string>('');
    const [callLoading, setCallLoading] = useState<boolean>(false);

    const [data, setData] = useState<MachineDataList>({list: [], totalCount: 0});

    const loadData = () => {
        axiosPrivate.get(`/tasks/list?page=${currentPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}&size=${itemsPerPage}`)
            .then(response => {
                if (response.status === 200 && response.data.data) {
                    setData(
                        {
                            list: transformJsonToMachineDataList(response.data.data.list),
                            totalCount: response.data.data.total_count
                        }
                    );
                }
            })
            .catch(e =>
                console.log(e)
            );
    }

    const handleOpenTasks = (tasks: WorkingTask[]) => {
        onOpen('tasks-modal', {tasksData: tasks});
    }

    useEffect(() => {
        loadData();
    }, [currentPage, itemsPerPage, sorted, search]);

    useEffect(() => {
        if (!callLoading) {
            setCurrentPage(1);
            loadData();
        } else {
            setData({list: [], totalCount: 0});
        }
    }, [callLoading]);

    return (
        <Card isExpanded={true} title={"Machines Tasks Data"} callExpanded={setCallLoading}>
            <div className={"flex flex-col w-full transition-all gap-3"}>
                <Table onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                    <TableHeader>
                        <TableColumnHeaderCell>
                            Name Machine
                        </TableColumnHeaderCell>
                        <TableColumnHeaderCell>
                            Login
                        </TableColumnHeaderCell>
                        <TableColumnHeaderCell>
                            Status
                        </TableColumnHeaderCell>
                        <TableColumnHeaderCell>
                            Time Update
                        </TableColumnHeaderCell>
                        <TableColumnHeaderCell className={"w-24"}/>
                    </TableHeader>

                    <TableBody>
                        {data.list.map((item, index) => (
                            <TableRow key={index} rowIndex={index}>
                                <TableCell>
                                    {item.name}
                                </TableCell>
                                <TableCell>
                                    {item.account}
                                </TableCell>
                                <TableCell>
                                    {item.status}
                                </TableCell>
                                <TableCell>
                                    {getDateByLocale(item.dateUpdate, language)}
                                </TableCell>
                                <TableCell>
                                    <div className={"flex flex-row justify-center gap-2 p-2"}>
                                        {/*<ActionTooltip label={translate('keywords.open-play-market')}>*/}
                                        <ActionTooltip label={"Show tasks"}>
                                            <div className={"flex w-full justify-center"}>
                                                <ChevronRight
                                                    className={"cursor-pointer bg-orange/40 hover:bg-orange/80 rounded-md p-1 transition-all"}
                                                    onClick={() => item.tasks && handleOpenTasks(item.tasks)}
                                                    size={20}/>
                                            </div>
                                        </ActionTooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
                <TablePagination totalItems={data.totalCount}
                                 currentPage={currentPage}
                                 itemsPerPage={itemsPerPage}
                                 onChangeItemsPerPage={setItemsPerPage}
                                 onChangePage={setCurrentPage}/>
            </div>
        </Card>
    );
}
