import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {Button} from "../../components/shared/button";
import {Input} from "../../components/shared/Input";
import {ChevronRight, SearchIcon, Trash} from "lucide-react";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {ServerDataList, transformJsonToServerDataListCount} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useModal} from "../../hooks/use-modal-store";
import {useToast} from "../../contexts/ToastContext";
import {ActionTooltip} from "../../components/shared/tooltip";

const ServersScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {onOpen} = useModal();
    const {add} = useToast();

    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>('');
    const [serverData, setServerData] = useState<ServerDataList>({list: [], totalCount: 0});

    const handlerAddServer = () => {
        onOpen("add-server", {}, {
            onConfirm: () => {
                loadServers();
            }
        });
    }

    const clickDetail = (id: number) => {
        onOpen("add-server", {serverId: id}, {
            onConfirm: () => {
                loadServers();
            }
        })
    }

    const clickDelete = (id: number) => {
        onOpen("confirm-modal", {
                confirmData: {
                    title: translate('def.delete') + '?',
                    description: translate('servers.modal.delete-description'),
                    button_confirm: translate('def.delete'),
                    button_close: translate('def.cancel')
                }
            }, {
                onConfirm: () => {
                    axiosPrivate.delete(`/servers/delete?id=${id}`).then((response) => {
                        if (response.status === 200) {
                            add({message: translate('servers.modal.delete-success'), type: 'success'});
                            loadServers();
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        );
    }

    const loadServers = () => {
        axiosPrivate.get(`/servers/list?page=${currentPage}&search=${search}&size=${itemsPerPage}&sort=${sorted.key + ':' + sorted.ask}`).then((response) => {
            if (response.status === 200) {
                setServerData(transformJsonToServerDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadServers();
    }, [axiosPrivate]);

    useEffect(() => {
        loadServers();
    }, [axiosPrivate, currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadServers();
    }, [axiosPrivate, search, sorted]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('servers.list.title-page')}</span>
            <Card>
                <div className={"flex flex-row justify-between"}>
                    <div className={"flex flex-row w-52"}>
                        <Input
                            type={"search"}
                            label={translate('def.search')}
                            icon={<SearchIcon size={20}/>}
                        />
                    </div>
                    <Button onClick={handlerAddServer}>
                        {translate('servers.list.add-server')}
                    </Button>
                </div>

                <div className={"flex flex-col gap-4"}>
                    <Table selectable loading={loading} onSorted={(key, ask) => setSorted({key, ask})}
                           onSelect={(indexes) => console.log(indexes)}>
                        <TableHeader>
                            <TableColumnHeaderCell>
                                Id
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell sortKey={"name"}>
                                Name
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                Type
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                Ip
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell className={"w-12"}>
                                {translate('def.actions')}
                            </TableColumnHeaderCell>
                        </TableHeader>

                        <TableBody>
                            {serverData.list.map((item, index) => (
                                <TableRow key={index} rowIndex={index}>
                                    <TableCell>
                                        {item.id}
                                    </TableCell>
                                    <TableCell>
                                        {item.type}
                                    </TableCell>
                                    <TableCell>
                                        {item.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.ip}
                                    </TableCell>
                                    <TableCell>
                                        <div className={"flex w-full justify-center gap-2 p-2"}>
                                            <ActionTooltip label={translate('def.details')}>
                                                <ChevronRight
                                                    className={"cursor-pointer bg-green/40 hover:bg-green/80 rounded-md p-1"}
                                                    size={20} onClick={() => clickDetail(item.id!!)}/>
                                            </ActionTooltip>
                                            <ActionTooltip label={translate('def.delete')}>
                                                <Trash
                                                    className={"cursor-pointer bg-red/40 hover:bg-red/80 rounded-md p-1"}
                                                    size={20} onClick={() => clickDelete(item.id!!)}/>
                                            </ActionTooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                    <TablePagination
                        totalItems={serverData.totalCount}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onChangeItemsPerPage={setItemsPerPage}
                        onChangePage={setCurrentPage}
                    />
                </div>
            </Card>
        </div>
    )
}

export default ServersScene
