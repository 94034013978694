import React, {useState} from "react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";
import {useToast} from "../../contexts/ToastContext";
import {useAuth} from "../../contexts/AuthContext";
import {AxiosError} from "axios";
import {axiosPrivate} from "../../config/axios";
import {Button} from "../shared/button";
import {Input} from "../shared/Input";
import {Checkbox} from "../shared/checkbox";
import {LoaderLayout} from "../layouts/LoaderLayout";
import {ErrorData} from "../../lib/transform-json";

const Auth: React.FC = () => {

    const {translate} = useLanguage();
    const [activeTab, setActiveTab] = useState<'signIn' | 'signUp'>('signIn');

    //TODO Подумать - правильно это?
    if (localStorage.getItem('user') != null && localStorage.getItem('user') !== 'null') {
        window.location.href = '/';
        return <LoaderLayout/>;
    }

    return (
        <div
            className="flex flex-col gap-2 h-screen w-screen bg-main dark:bg-main-dark items-center pt-24 transition-all overflow-scroll">
            <div
                className="flex flex-col overflow-hidden w-[350px] h-[45px] shadow-green/20 dark:shadow-green-dark/20 shadow-md justify-center rounded-xl bg-white dark:bg-gray-dark">
                <div className="flex flex-grow m-2 justify-center items-center">
                    <div className="cursor-pointer flex flex-col w-52 items-center"
                         onClick={() => setActiveTab('signIn')}>
                        <button
                            className={`w-full hover:text-green hover:font-bold ${activeTab === 'signIn' ? 'text-green font-bold' : 'text-gray border-b-0 '} border-green/70 transition-all`}
                        >
                            {translate('auth.sign_in_tab')}
                        </button>
                        <div
                            className={`rounded h-1 bg-green/70 dark:bg-green-dark/70 ${activeTab === 'signIn' ? 'w-full' : 'w-0 '} transition-all`}/>
                    </div>
                    <div className="cursor-pointer flex flex-col w-52 items-center"
                         onClick={() => setActiveTab('signUp')}>
                        <button
                            className={`w-full hover:text-green hover:font-bold ${activeTab === 'signUp' ? 'text-green font-bold' : 'text-gray'} transition-all`}
                        >
                            {translate('auth.sign_up_tab')}
                        </button>
                        <div
                            className={`rounded h-1 bg-green/70 dark:bg-green-dark/70 ${activeTab === 'signUp' ? 'w-full' : 'w-0 '} transition-all`}/>
                    </div>
                </div>
            </div>
            <div className="w-[350px] perspective-1000">
                <div
                    className={`relative w-full mt-2 h-fit ${activeTab === 'signUp' ? 'rotate-y-180' : ''} transform-style-3d transition duration-1000`}>
                    <div
                        className="top-0 left-0 w-full backface-hidden absolute rotate-y-0 rounded-xl bg-white dark:bg-gray-dark shadow-green/20 dark:shadow-green-dark/20 shadow-md ">
                        <SingIn/>
                    </div>
                    <div
                        className={`top-0 left-0 absolute backface-hidden w-full rotate-y-180 rounded-xl bg-white dark:bg-gray-dark shadow-green/20 dark:shadow-green-dark/20 shadow-md`}>
                        <SingUp changeTab={setActiveTab}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;

interface SingUpProps {
    changeTab: (tab: 'signIn' | 'signUp') => void;
}

const SingUp: React.FC<SingUpProps> = ({changeTab}) => {

    const {translate} = useLanguage();

    const {add} = useToast();

    const [error, setError] = useState<Record<string, { value: string }>>({});

    const [form, setForm] = useState({
        login: '',
        email: '',
        name: '',
        surname: '',
        password: '',
        password2: ''
    });

    const [loading, setLoading] = useState(false);

    const handleRegister = () => {

        setError({});
        setLoading(true);
        let hasError = false;

        if (form.login === '') {
            setError(prev => ({...prev, login: {value: 'login_is_require'}}));
            hasError = true;
        } else if (form.login.length < 4) {
            setError(prev => ({...prev, login: {value: 'login_is_short'}}));
            hasError = true;
        }

        if (form.email === '') {
            setError(prev => ({...prev, email: {value: 'email_is_require'}}));
            hasError = true;
        } else if (!form.email.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i)) {
            setError(prev => ({...prev, email: {value: 'email_is_invalid'}}));
            hasError = true;
        }

        if (form.name === '') {
            setError(prev => ({...prev, name: {value: 'name_is_require'}}));
            hasError = true;
        }

        if (form.surname === '') {
            setError(prev => ({...prev, surname: {value: 'surname_is_require'}}));
            hasError = true;
        }

        if (form.password === '') {
            setError(prev => ({...prev, password: {value: 'password_is_require'}}));
            hasError = true;
        }

        if (form.password.length < 8) {
            setError(prev => ({...prev, password: {value: 'password_is_short'}}));
            hasError = true;
        }

        if (form.password && form.password !== form.password2) {
            setError(prev => ({...prev, password2: {value: 'passwords_not_match'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.post('/auth/register', {
            login: form.login,
            name: form.name,
            surname: form.surname,
            email: form.email,
            password: form.password
        }).then((response) => {
            if (response.status === 200) {
                changeTab('signIn');
                setForm({
                    login: '',
                    email: '',
                    name: '',
                    surname: '',
                    password: '',
                    password2: ''
                });
            }
        }).catch((error) => {
            setLoading(false);

            const axiosError = error as AxiosError<ErrorData>;

            if (axiosError.response) {
                console.log(axiosError.response);
                if (axiosError.response.status === 400) {
                    add({message: axiosError.response.data?.error_message, type: "error"});
                    setError(prev => ({...prev, email: {value: 'email_is_exist'}}));
                    setError(prev => ({...prev, login: {value: 'login_is_exist'}}));
                } else if (axiosError.response.status === 401) {
                    add({message: 'Login or password incorrect.', type: "error"});
                } else {
                    add({message: 'Something went wrong. Please try again.', type: "error"});
                }
            } else if (axiosError.request) {
                add({message: 'No response from the server. Please try again.', type: "error"});
            } else {
                add({message: 'Error during the login process. Please try again.', type: "error"});
            }
        });
    }

    return (

        <form onSubmit={(event) => {
            event.preventDefault();
            handleRegister();
        }}>
            <div className="w-full flex flex-col p-4 gap-4">
                <Input
                    id="login"
                    type="text"
                    value={form.login}
                    onChange={(e) => setForm(prev => ({...prev, login: e.target.value}))}
                    label={translate('auth.login')}
                    error={error.login?.value || ''}/>

                <Input
                    id="email"
                    type="email"
                    value={form.email}
                    onChange={(e) => setForm(prev => ({...prev, email: e.target.value}))}
                    label={translate('auth.email')}
                    error={error.email?.value || ''}/>

                <div className={"flex flex-row gap-2"}>
                    <Input
                        id="name"
                        type="text"
                        value={form.name}
                        onChange={(e) => setForm(prev => ({...prev, name: e.target.value}))}
                        label={translate('auth.name')}
                        error={error.name?.value || ''}/>
                    <Input
                        id="surname"
                        type="text"
                        value={form.surname}
                        onChange={(e) => setForm(prev => ({...prev, surname: e.target.value}))}
                        label={translate('auth.surname')}
                        error={error.surname?.value || ''}/>
                </div>

                <Input
                    id="password"
                    type="password"
                    value={form.password}
                    onChange={(e) => setForm(prev => ({...prev, password: e.target.value}))}
                    label={translate('auth.password')}
                    error={error.password?.value || ''}/>

                <Input
                    id="password2"
                    type="password"
                    value={form.password2}
                    onChange={(e) => setForm(prev => ({...prev, password2: e.target.value}))}
                    label={translate('auth.password_again')}
                    error={error.password2?.value || ''}/>

                <Button type="submit" disabled={loading} className="mt-4 w-full disabled:bg-gray"
                        onClick={handleRegister}>{translate('auth.sign_up_button')}</Button>
            </div>
        </form>
    );
}

const SingIn: React.FC = () => {

    const {translate} = useLanguage();

    const {add} = useToast();

    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [error, setError] = useState<Record<string, { value: string | undefined }>>({});
    const [form, setForm] = useState({
        login: '',
        password: '',
        remember_me: true
    });

    const [loading, setLoading] = useState(false);

    const handleLogin = () => {

        setLoading(true);

        setError({});
        let hasError = false;

        if (form.login === '') {
            hasError = true;
            setError(prev => ({...prev, login: {value: 'login_is_require'}}));
        }
        if (form.password === '') {
            hasError = true;
            setError(prev => ({...prev, password: {value: 'password_is_require'}}));
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.post('/auth/login', {
            login: form.login,
            password: form.password,
            remember_me: form.remember_me
        }).then((response) => {
            if (response.status === 200) {
                auth.setUser(response.data.data);

                add({message: 'You have successfully logged in', type: "success"});

                setTimeout(() => {
                    navigate(from, {replace: true});
                }, 1000);
            }
        }).catch((error) => {
            setLoading(false);

            const axiosError = error as AxiosError<ErrorData>;

            if (axiosError.response) {
                if (axiosError.response.status === 400) {
                    add({message: axiosError.response.data?.error_message, type: "error"});
                } else {
                    // Обработка других ошибок сервера
                    add({message: 'Something went wrong. Please try again.', type: "error"});
                }
            } else if (axiosError.request) {
                // Запрос был сделан, но нет ответа
                add({message: 'No response from the server. Please try again.', type: "error"});
            } else {
                // Произошло что-то в процессе установления запроса,
                // что вызвало ошибку
                add({message: 'Error during the login process. Please try again.', type: "error"});
            }
        });
    }

    return (

        <form onSubmit={(event) => {
            event.preventDefault();
            handleLogin();
        }}>
            <div className={`w-full flex flex-col p-4 gap-4`}>
                <Input
                    id="login"
                    type="text"
                    label={translate('auth.login')}
                    value={form.login}
                    onChange={(e) => setForm(prev => ({...prev, login: e.target.value}))}
                    error={error.login?.value}/>

                <Input
                    id="password"
                    type="password"
                    value={form.password}
                    onChange={(e) => setForm(prev => ({...prev, password: e.target.value}))}
                    label={translate('auth.password')}
                    error={error.password?.value}/>

                <Checkbox
                    label={translate('auth.remember_me')}
                    onCheck={(checked) => setForm(prev => ({...prev, remember_me: checked}))}
                    defaultChecked={form.remember_me}/>

                <Button type="submit" disabled={loading} className="mt-4 w-full disabled:bg-gray">
                    {translate('auth.sign_in_button')}
                </Button>
            </div>
        </form>
    );
}