import {useModal} from "../../hooks/use-modal-store";
import {useToast} from "../../contexts/ToastContext";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Separator} from "../shared/separator";
import {Button} from "../shared/button";
import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {WorkingTask} from "../../lib/transform-json";
import {Avatar, AvatarFallback, AvatarImage} from "../shared/avatar";
import {ActionTooltip} from "../shared/tooltip";
import {getDateByLocale} from "../../lib/utils";

export const TasksModal = () => {
    const {isOpen, onClose, type, data} = useModal();
    const {add} = useToast();
    const {translate, language} = useLanguage();

    const isModalOpen = isOpen && type === "tasks-modal";

    if (isModalOpen) {
        if (!data.tasksData) {
            add({
                type: "error",
                message: "Not found tasks",
            });
            onClose();
        }
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 min-w-[800px]">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        Tasks
                    </DialogTitle>
                    <Separator/>
                </DialogHeader>
                <div className="max-h-144 p-6 overflow-scroll">
                    <div className="flex flex-col gap-2 text-gray-dark dark:text-white">
                        {data.tasksData?.map((task: WorkingTask, index) => (
                            <div className={"flex flex-row gap-5 border-b-[1px] p-1 border-gray items-center text-xs"} key={index}>
                                <ActionTooltip label={task.app.app_id}>
                                    <Avatar className={`h-10 w-10 transition-all`}>
                                        <AvatarFallback delayMs={500}>
                                            {Object.values(task.app.details)[0].name.charAt(0).toUpperCase()}
                                        </AvatarFallback>
                                        <AvatarImage
                                            src={Object.values(task.app.details)[0].image_src}
                                        />
                                    </Avatar>
                                </ActionTooltip>
                                <span>
                                    Type: {task.task.type}
                                </span>
                                {task.task.other_params['keyword'] && (
                                    <span>
                                        {task.task.other_params['keyword']}
                                    </span>
                                )}
                                <span className={`${task.status === 'SUCCESS' ? 'text-green' : task.status === 'NONE' ? 'text-orange' : ''}`}>
                                    Status: {task.status}
                                </span>
                                <span>Update: {getDateByLocale(task.time_update, language)}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 p-4">
                    <Button className={"bg-gray/50 hover:bg-gray/90"} onClick={handleClose}>
                        {translate('def.cancel')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
