import React from "react";
import {Loader} from "../shared/loader";

export const LoaderLayout: React.FC = () => (
    <div className="flex flex-col gap-4 w-screen h-screen bg-main dark:bg-main-dark justify-center items-center">
        <Loader/>
        <div className={"flex flex-row gap-10"}>
            <div className={"flex text-2xl font-bold text-green animate-smoke"}>L</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-100"}>o</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-200"}>a</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-300"}>d</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-400"}>i</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-500"}>n</div>
            <div className={"flex text-2xl font-bold text-green animate-smoke delay-600"}>g</div>
        </div>
    </div>
);
