import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";

export const ActiveMachinesModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "active-machines";

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 overflow-hidden">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        Machines on {data.dataServer?.name_server}
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    {data.dataServer?.active.map((item: any) => (
                        <div className={"flex"}>
                            <span>{item.name}</span>
                        </div>
                    ))}
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button>
                        Save
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}