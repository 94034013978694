import React from "react";
import {cn} from "../../lib/utils";

type LoaderProps = {
    className?: string;
}

export const Loader: React.FC<LoaderProps> = ({className}) => (
    <div className={cn("", className)}>
        <div className={"flex flex-col w-[80px] h-[80px] gap-2"}>
        <div className="flex flex-row gap-2">
            <div
                className="w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '0s'}}></div>
            <div
                className="w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-0.4s'}}></div>
            <div
                className="w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-0.8s'}}></div>
        </div>
        <div className="flex flex-row gap-2">
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-0.4s'}}></div>
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-0.8s'}}></div>
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-1.2s'}}></div>
        </div>
        <div className="flex flex-row gap-2">
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-0.8s'}}></div>
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-1.2s'}}></div>
            <div
                className="flex w-[16px] h-[16px] rounded-full bg-green animate-[opacity_1.2s_infinite]"
                style={{animationDelay: '-1.6s'}}></div>
        </div>
        </div>
    </div>
);